import { SET_GAME_CODE, SET_GAME_FULLSCREEN, SET_GAME_INFO, SET_GAME_CLOSE } from "../actions/actionTypes"

const initialState = {
  code: '',
  fullscreen: true,
  info: {},
}

export const gameReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GAME_CODE:
      
      return {
        ...state,
        code: action.payload,
      }
    case SET_GAME_FULLSCREEN:
      return {
        ...state, gameFrame: {
          ...state.gameFrame, fullscreen: action.payload,
        },
      }
    case SET_GAME_INFO:
      return {
        ...state,
        info: action.payload,
      }
    case SET_GAME_CLOSE:
      return initialState
    default:
      return state;
  }
}
