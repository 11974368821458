import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setActiveGameList } from '@bets/components/redux/actions'
import { ProvidersBar } from 'components'
import { JackpotListPanel } from '@bets/components'
import { ButtonFullscreen, ButtonClose, ButtonLanguages, News, Search } from '@bets/elements'

import { AccountButton } from './AccountButton'
import './Header.scss'

import logo from "./assets/logo@2x.png";

export const Header = () => {
    
	const dispatch = useDispatch()
	const size = useSelector(state => state.handling.size)
	const jackpotsShow = useSelector(state => state.config.jackpotsShow)
	const gameCode = useSelector(state => state.game.code)
	const gameFullscreen = useSelector(state => state.game.fullscreen)
	const preloader = useSelector(state => state.handling.preloader)
	const authorized = useSelector(state => state.profile.authorized)

	const renderControls = () => {
		if ( gameCode ) {
			if (size.mobile && !gameFullscreen) {
				return (
					<div className="controls">
						<AccountButton />
						<ButtonFullscreen />
						<ButtonClose action={ () => window.core.closeGame() } />
					</div>
				)
			} else {
				return (
					<div className="controls">
						{/*<ButtonLanguages direction={'bottom'}/> */}
						{/*<ButtonFullscreen /> */}
						<AccountButton />
						<ButtonClose action={ () => window.core.closeGame() } />
					</div>
				)
			}
		} else {
			return (
				<div className="controls">
					{ size.mobile && !size.landscape && <Search /> }
					{ size.mobile && !size.landscape && <ButtonLanguages direction={'bottom'}/> }
					<AccountButton />
				</div>
			)
		}
	}

	const setPosition = () => {
		if (!size.mobile && !size.landscape && jackpotsShow && jackpotsShow.header) {
			return { position: 'absolute'}
		} else {
			return { position: 'static'}
		}
	}
	return (
		<header className="header" style={ setPosition() } data-visible={ preloader ? false : true}>
			<News />
			<div className="container">
				<div className="header__logo" onClick={ () => dispatch( setActiveGameList('all'))}>
					<img src={logo} alt="Logo" />
				</div>
				
				{ /*authorized && size.landscape && (jackpotsShow && jackpotsShow.launcher) &&
					<JackpotList/>
				*/}

				{authorized && size.landscape && (jackpotsShow && jackpotsShow.launcher) &&
					<JackpotListPanel theme='royal'/>
				}

				{ !size.mobile && !gameCode && jackpotsShow && !jackpotsShow.header && <ProvidersBar /> }
				
				{ renderControls() }
					
			</div>
			{ /* !size.mobile && !gameCode && size.landscape && jackpotsShow && jackpotsShow.header && <ProvidersBar/> */}
		</header>
	)
}