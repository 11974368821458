import React from 'react'
import { useSelector } from 'react-redux'

import './Success.scss'

export const Success = ({ 
	operationId = false,
	title = 'Success',
	info = false,
	onClose
	}) => {

	const handleClose = event => onClose( event )

	return (
		<div className='Success'>
			<div className="title"> { title } </div>

			{ operationId && <span className="id">{  "ID: " + operationId }</span>}
			<div className="close" onClick={ handleClose }>
				BACK
			</div>
			{ info && <p className='info'>{ info }</p>}
		</div>
	)
}