import React from "react"
import { useSelector } from 'react-redux'

import { Helmet } from "react-helmet"

export const Head = () => { 
   
    const settings = useSelector(state => state.settings)

    let meta = settings.meta.map( tag => {
        let property = Object.keys(tag)[0],
        content = Object.values(tag)[0]
        return <meta key={ Object.keys(tag)[0] } property={ property } content={ content }/>
    })

    return (
        <Helmet defaultTitle={ settings.title }>
            <title>{ settings.title }</title>
            { meta }

            <meta property="og:title" content={ settings.title }/>
            <meta property="og:image" content="images/favicon.png" />
            <meta property="og:url" content={ window.location.host } />
            <meta property="og:type" content="website"/>

            <link rel="apple-touch-icon" sizes="57x57" href="images/pwa/apple-touch-icon/apple-touch-icon-57x57.png"/>
            <link rel="apple-touch-icon" sizes="114x114" href="images/pwa/apple-touch-icon/apple-touch-icon-114x114.png"/>
            <link rel="apple-touch-icon" sizes="72x72" href="images/pwa/apple-touch-icon/apple-touch-icon-72x72.png"/>
            <link rel="apple-touch-icon" sizes="144x144" href="images/pwa/apple-touch-icon/apple-touch-icon-144x144.png"/>
            <link rel="apple-touch-icon" sizes="60x60" href="images/pwa/apple-touch-icon/apple-touch-icon-60x60.png"/>
            <link rel="apple-touch-icon" sizes="120x120" href="images/pwa/apple-touch-icon/apple-touch-icon-120x120.png"/>
            <link rel="apple-touch-icon" sizes="76x76" href="images/pwa/apple-touch-icon/apple-touch-icon-76x76.png"/>
            <link rel="apple-touch-icon" sizes="152x152" href="images/pwa/apple-touch-icon/apple-touch-icon-152x152.png"/>
            { settings.gtag && <script async src={ "https://www.googletagmanager.com/gtag/js?id=" + settings.gtag }></script> }

    
        </Helmet>
    );
};