import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";

import {
  getGameCode,
  setGameInfo,
  getGameList,
  setGameClose,
  getAuthorization,
  setBeforeAuthorize,
  getProfileInfo,
  getWords,
  getWindowSize,
  setPromo,
  getPopup,
  getAuthForm,
  setAudioEvent,
  resetStore,
  setPreloader
} from '@bets/components/redux/actions'
import { Terms, Wheels } from '@bets/components'
import { 
  Attention, 
  FullScreen, 
  Sound,
  JivoSiteWidget, 
  VirtualKeyboard, 
  PleaseRotate,
  Head,
  Pntz
} from '@bets/helpers'
  
import _ from "lodash"

import wordsFromServer from './wordsServer.json'
import wordsFromLocal from './wordsLocal.json'

export const App = ({ children }) => {
	const navigate = useNavigate()

  const dispatch = useDispatch()
  const settings = useSelector(state => state.settings)
  const handling = useSelector(state => state.handling)
  const profile = useSelector( state => state.profile)

  const starReload = () => {
    dispatch(setPreloader(true))
    dispatch(getPopup({ active: false, name: '' }))
  }

  const beforeAuthorize = () => {
    let response = window.core.query({ 'beforeAuthorize': true })

    if (response.status === 'success') {
      dispatch( setBeforeAuthorize(true) )
      if (!window.config.system.authorized) {
        window.core.jackpotsDraw(response.jackpots)
        dispatch( getGameList(response.gameList) ) 
      }
    } else {
        dispatch( setBeforeAuthorize(false) )
        settings.routing && navigate('/auth')
      }
  }

  useEffect(() => {

    window.core.functions.userLogin = (response) => {
      if (response.status === 'success') {
        //dispatch( getAudioPlayer(false))
        dispatch( setAudioEvent('login_success') )
        window.core.getGameList()
        dispatch( getAuthorization(true) )
        
        if (response.content.language !== window.config.system.language) {
          window.core.setLanguage(response.content.language)
        } else {
          dispatch( getPopup({ active: false, name: '' }))
        }
        if(!profile.beforeAuthorize) {
          dispatch( setPreloader(true))
          settings.routing && navigate('/')
          setTimeout(function () {
            dispatch( setPreloader(false))
          }, 3000)
        }
      }
    }

    // получаем список слов
    setThemeProperties()
    dispatch ( getWords(window.core.getWords({ server: wordsFromServer, local: wordsFromLocal })) )
    dispatch(getAuthorization(window.config.system.authorized))

    if (!profile.authorized) {
      beforeAuthorize()
      //Обработка ссылки логина
      if (window.core.get.u && window.core.get.p) {
        //console.log('login on link')
        window.core.userLogin(core.get.u, core.get.p, 0, 0);
       
      }

      // Обработка регистрации
      if (window.core.get.registration) {
        dispatch( getAuthForm('signin'))
        //console.log(profile.beforeAuthorize)
        profile.beforeAuthorize && dispatch( getPopup({ active: true, name: 'auth'}) )
      }

      // Обработка промокода
      if (window.core.get.promo) {
        dispatch( setPromo(window.core.get.promo) )
      }

      // Обработка кода на восстановление
      if (window.core.get.code !== undefined) {
        dispatch( getAuthForm('recovery'))
        profile.beforeAuthorize && dispatch( getPopup({ active: true, name: 'auth'}) )
        window.history.pushState({}, '', window.location.href.replace(window.location.search,''))
      }
    }

    settings.preloaderOnReload && window.addEventListener("beforeunload", starReload)
    updateDimensions()
    // обработчик изменения размера сайта 
    window.addEventListener("resize", _.debounce(updateDimensions, 300))

    // получаем информацию профиля
    window.core.functions.reloadPage.push(function () {

      dispatch( 
        getProfileInfo({ ...window.core.terminal.content }) 
      )
    })
    // получаем список игр
    window.core.functions.drawGameList.push(function () {
      if (window.core.gameList) {
        dispatch( getGameList(window.core.gameList) ) //получить список игр
      }

    })

    
    window.core.functions.userLogout = () => {
      dispatch( resetStore() )
      window.core.closeGame()
      dispatch( setPreloader(false) )
      dispatch( getPopup({ active: false, name: '' }) )
      beforeAuthorize()
      dispatch( getAuthorization(false) )
    }

    window.core.functions.openGame = () => {
      //console.log(window.core.gameInfo)
      dispatch( getGameCode(window.core.gameInfo.code) )
      dispatch( setGameInfo(window.core.gameInfo, window.core.gameList) )
    }

    window.core.functions.closeGame = () => {
      dispatch( setGameClose('') )
    }
  

    window.core.jackpotWinElem(".jackpotWin")
    return () => {
      window.removeEventListener("resize", updateDimensions)
      window.removeEventListener("beforeunload", starReload);

    }
  }, [])
  
  const setThemeProperties = () => {
    let theme = window.theme
    if (theme) {
      Object.keys(theme).forEach((key) => {
        const value = theme[key]
        document.documentElement.style.setProperty(key, value)
      })
    }
  }
  
  function updateDimensions() {
    let vh = window.innerHeight * 0.01
  // Then we set the value in the --vh custom property to the root of the document
   document.documentElement.style.setProperty('--vh', `${vh}px`)
    return dispatch(
      getWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    )
  }
  
  return (
    <>
        <Head/>
        { settings.virtualKeyboard && !handling.size.mobile && <VirtualKeyboard /> }
        { (handling.size.mobile) && <FullScreen/> }
        { settings.jivosite && <JivoSiteWidget id={ settings.jivosite }/> }
        { profile.detect && children }
        { settings.pleaseRotate && <PleaseRotate /> }
        { !(config.reactJackpotWin && (config.reactJackpotWin === 'jacuts' || config.reactJackpotWin === 'jacuts_royal')) && <div className="jackpotWin"></div>}
        { /*['jacuts', 'jacuts_royal'].indexOf(config.reactJackpotWin) === -1 && <div className="jackpotWin"></div> */}
        <Attention />
        { settings.sound && <Sound /> } 
        <Wheels/> 
        { (settings.pntz && handling.activePopup.name === 'pntz') && <Pntz domainKey={ settings.pntz }/> }
        { (handling.activePopup && handling.activePopup.name === 'terms') && <Terms /> }
    </>
  )
}