import React, { useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setActiveInput } from '@bets/components/redux/actions'
import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'
import './VirtualKeyboard.scss'


export const VirtualKeyboard = () => { 

const dispatch = useDispatch()
const activeInput = useSelector(state => state.handling.activeInput)
const preloader = useSelector(state => state.handling.preloader)

const [visible, setVisible] = useState(false)

const keyboard = useRef()
const keyboardRef = useRef()

window.VirtualKeyboard = keyboard.current

const handleClickOutside = (e) => {
	if ( keyboardRef.current ) {
		if (!keyboardRef.current.contains(e.target) && e.target.tagName != 'INPUT') {
			setVisible(false)
			settings.virtualKeyboard && dispatch( setActiveInput(null) )

		}
	}
}

useEffect(() => {
	document.addEventListener("click", handleClickOutside )

	return () => {
		document.removeEventListener("click", handleClickOutside);
	  };
}, [])

useEffect(() => {
	if (activeInput) {
		setVisible(true)
	} else {
		window.VirtualKeyboard && window.VirtualKeyboard.clearInput()
	}
}, [activeInput])

const setNativeValue = (element, value) => {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set
    const prototype = Object.getPrototypeOf(element)
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(
      prototype,
      'value'
    ).set

    if (valueSetter && valueSetter !== prototypeValueSetter) {
      prototypeValueSetter.call(element, value)
    } else {
      valueSetter.call(element, value)
    }
  }


function onChange(value) {
	if (activeInput) {
		let el = document.getElementById(activeInput)
		var ev = new Event('input', { bubbles: true}, true)
		setNativeValue(el, value)
		el.dispatchEvent(ev)
		
	}
}

function onKeyPress(button) {
  if (button === "{lock}" || button === "{shift}") handleShiftButton();
}

function handleShiftButton() {
  let currentLayout = keyboard.current.options.layoutName;
  let shiftToggle = currentLayout === "default" ? "shift" : "default";

  keyboard.current.setOptions({
    layoutName: shiftToggle
  })
}

	return (
		<div ref={keyboardRef} className="VirtualKeyboard" data-visible={ visible && !preloader }>
			<Keyboard 
				theme={"hg-theme-default dark"} 
				onKeyPress={ button => onKeyPress(button) } 
				onChange={ input => onChange(input) } 
				inputName={ activeInput ? activeInput.id : 'default' } 
				keyboardRef={ r => (keyboard.current = r) }/>
		</div>
	)
}