import React from 'react'

import './WalletInput.scss'

export const WalletInput = ({ 
	value = 0,
	onChange,
	name,
	title,
	placeholder = title ? title : ''
	}) => {

	const handleChange = event => onChange( event )

	return (
		<div className='WalletInput'>
			<input 
				id="wallet_input"
				value={ value }
				name={ name }
				type="text"
				onChange={ handleChange } 
				placeholder={ placeholder }
			/>

		</div>
	)
}