import React from 'react'
import { useSelector } from 'react-redux'

import './AmountInput.scss'

export const AmountInput = ({ 
	placeholder = 0, 
	value = 0,
	min = false, 
	max = false,
	onChange
	}) => {
    
	const currency = useSelector(state => state.profile.info.currency)

	const handleChange = event => onChange( event )

	return (
		<div className='AmountInput'>
			<input 
				id='amount_input'
				value={ value }
				type="number"
				min={ min ? min : '' }
				max={ max ? max : '' }
				onChange={ handleChange } 
				placeholder={ placeholder }
			/>

			<div className="range">
				{ min && <div className="min">{ 'Min: ' + min + ' ' + currency }</div>}
				{ max && <div className="max">{  'Max: ' + max + ' ' + currency }</div>}
			</div>

		</div>
	)
}