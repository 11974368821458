import React, { useMemo } from 'react';
import { useSelector } from 'react-redux'
import { Item } from '../List/Item';
import classNames from 'classnames';

import "./ListPanel.scss";

export const ListPanel = ({ theme }) => {
    const jackpots = useSelector(state => state.profile.info.jackpots);

    const renderElements = useMemo(() => (jackpots || window.core.jackpots || []).map(jackpot =>
        <Item key={`JackpotItem-${jackpot.name}`} jackpot={jackpot} theme={theme} />
      ), [jackpots, window.core.jackpots]);

    return <div className={classNames('list-panel-block', 'spin-jackpots-list')}>{renderElements}</div>
}