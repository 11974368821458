import React from 'react'
import './Preloader.scss'

export const Preloader = ({ visible, children }) => {

	return ( 
		<div className="preloader" data-visible={ visible }>  
			{ !children && <div className="loader">
				<div className="image">
					<img src='images/spinner.svg' alt="Loading"/>
				</div>
			</div>}
			{ children }
		</div>
	)
}