import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPopup, setPreloader } from '@bets/components/redux/actions'
import SVG from 'react-inlinesvg'
import './ButtonLogout.scss'
import { formatUrlVersion } from '@bets/features'



export const ButtonLogout = ({ icon = "images/icons/exit.svg", children = null } ) => {

    const dispatch = useDispatch()
    const words = useSelector(state => state.words)
    const profile = useSelector(state => state.profile)
    const [visible, setVisible] = useState(true)

    const handleClick = () => {
        dispatch( setPreloader(true) )
        dispatch( getPopup({ active: false, name: '' }) )
        setTimeout( () => window.core.userLogout(), 200 )
    }

    useEffect(() => {
        if (profile && profile.info && profile.info.buttons && profile.info.buttons.exit === 0) {
            setVisible(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile])

    if (visible) return (
        <div className='Button ButtonLogout' onClick={ handleClick } >
            {!children ? <>
                <span>{ words.server.exit }</span>
                <SVG src={ formatUrlVersion(icon) } />
            </> : children }
        </div>
    )
    return <></>
}