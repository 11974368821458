
const initialState = window.settings

export const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
   
    default:
      return state;
  }
}
