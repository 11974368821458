export const GET_GAMELIST = "GET_GAMELIST"
export const GET_ACTIVE_GAMELIST = "GET_ACTIVE_GAMELIST"
export const GET_AUTHORIZATION = "GET_AUTHORIZATION"
export const SET_BEFORE_AUTHORIZE = "SET_BEFORE_AUTHORIZE"
export const GET_JACKPOTS = "GET_JACKPOTS"
export const GET_PROFILE_INFO = "GET_PROFILE_INFO"
export const GET_ACTIVE_POPUP = "GET_ACTIVE_POPUP"
export const GET_ACTIVE_AUTH = "GET_ACTIVE_AUTH"
export const GET_CONFIG = "GET_CONFIG"
export const GET_WORDS = "GET_WORDS"

export const SET_GAME_CODE = "SET_GAME_CODE"
export const SET_GAME_FULLSCREEN = "SET_GAME_FULLSCREEN"
export const SET_GAME_INFO = "SET_GAME_INFO"
export const SET_GAME_CLOSE = "SET_GAME_CLOSE"

export const GET_WINDOW_SIZE = "GET_WINDOW_SIZE"
export const GET_FAVORITE = "GET_FAVORITE"
export const GET_HISTORY_GAMES = "GET_HISTORY_GAMES"
export const GET_WAGER_TIME = "GET_WAGER_TIME"
export const GET_AUDIO = "GET_AUDIO"
export const SET_PRELOADER = "SET_PRELOADER"
export const GET_PROVIDERS_LIST = "GET_PROVIDERS_LIST"
export const GET_LINK_BONUS = "GET_LINK_BONUS"
export const GET_SEARCH = "GET_SEARCH"
export const SET_SEARCH_TERM = "SET_SEARCH_TERM"
export const RESET_STORE = "RESET_STORE"
export const SET_ACTIVE_INPUT = "SET_ACTIVE_INPUT"
export const SET_AUDIO_EVENT = "SET_AUDIO_EVENT"
export const SET_PROMO = "SET_PROMO"
export const SET_DEPOSIT_LIST = "SET_DEPOSIT_LIST"
export const SET_WITHDRAW_LIST = "SET_WITHDRAW_LIST"
export const SET_SELECTED_DEPOSIT = "SET_SELECTED_DEPOSIT"
export const SET_SELECTED_WITHDRAW = "SET_SELECTED_WITHDRAW"