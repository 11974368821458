import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";

import './SpinRotateAnimation.scss';
import './Spin.scss';

import fortuneSoundSrc from '../assets/fortune_wheel_final.mp3';
const audio = new Audio(fortuneSoundSrc);
audio.type = "audio/mp3";
audio.volume = 0.5;

const randomIntFromInterval = (min, max) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export const Spin = ({ pageName, hiding, winJackpotName, completeCallback, completeHidingSpinPage, theme }) => {
    const [jackpotAnimationName, setJackpotAnimationName] = useState('jackpot__rotate-waiting');

    useEffect(() => {
        if (pageName === 'intro' && jackpotAnimationName !== 'jackpot__rotate-waiting') {
            setJackpotAnimationName('jackpot__rotate-waiting');
        }

        if (pageName === 'spin' && jackpotAnimationName === 'jackpot__rotate-waiting' && winJackpotName) {
            const jackpotName = winJackpotName === 'diamond' || winJackpotName === 'platinum'
                ? winJackpotName : `${winJackpotName}${randomIntFromInterval(1, 2)}`;

            setJackpotAnimationName(`jackpot__rotate-to-${jackpotName}`);
            setTimeout(() => {
                audio.play();
            }, 0); // start fortune sound
        }
    }, [pageName, winJackpotName]);

    const animationEnd = () => { // animation only ended, when spin stopped
        completeCallback();
    }

    useEffect(() => {
        return () => {
            audio.pause();
            audio.currentTime = 0;
        };
    }, []);

    return <>
        <div className={classNames('jackpot-spin', `jackpot-spin-page-${pageName}`, {
            'jackpot-spin-hiding': hiding,
            'jackpot-spin-royal': theme === 'royal'
        })} onTransitionEnd={completeHidingSpinPage}
        >
            <div className={classNames('jackpot-spin__selector', {
                'jackpot-spin__selector-royal': theme === 'royal'
            })}></div>
            <div onAnimationEnd={animationEnd}
                className={classNames('jackpot-spin__wheel', jackpotAnimationName, {
                    'jackpot-spin__wheel-royal': theme === 'royal'
                })}>
            </div>
        </div>
    </>
};

Spin.propTypes = {
    completeCallback: PropTypes.func,
    pageName: PropTypes.oneOf(['intro', 'spin']),
    winJackpotName: PropTypes.oneOf(['diamond', 'platinum', 'gold', 'silver', 'bronze', 'iron']),
    hiding: PropTypes.bool,
    theme: PropTypes.oneOf(['royal', 'default'])
}

Spin.defaultProps = {
    completeCallback: () => { },
    pageName: 'intro',
    winJackpotName: undefined,
    hiding: false,
    theme: 'default'
}