import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAudioPlayer } from '@bets/components/redux/actions'

import './ButtonSound.scss'

export const ButtonSound = () => {
    
    const dispatch = useDispatch()
    const paused = useSelector(state => state.handling.sound.paused)

    return <div className='Button ButtonSound' data-mute={ paused } onClick={ () => dispatch( getAudioPlayer(!paused))} />
}