import React, { useState, useEffect, useRef } from 'react'
import { Wheel } from './Wheel'
import { useSelector } from 'react-redux'
import { ButtonClose } from '@bets/elements'

import './Wheels.scss'
const audio = require("./assets/fortune_wheel_final.mp3").default

//const mockValues = [5, 25, 10, 15, 20, 10, 15, 20, 5, 225, 5, 25, 10, 7, 20, 10, 15, 20, 5, 25, 5, 25, 10, 15, 5, 10]
//const mockWin = 25

export const Wheels = () => {

    const soundPlayer = useRef(null)
    const profileInfo = useSelector( state => state.profile.info)
    const [init, setInit] = useState(false)
    const [visible, setVisible] = useState(false)

    const [values, setValues] = useState(null)
    const [win, setWin] = useState(null)
    const [sound, setSound] = useState(true)
    const [isFreespins, setIsFreespins] = useState(null)

    useEffect(() => {
        if (profileInfo.wheels && profileInfo.wheels !== '' && !init) {
            console.log('init wheel')
            let type = profileInfo.wheels.includes('freespins_wheel')
            setIsFreespins(type)
            let response = window.core.query({ 'cmd': 'fortuneWeheelInit' })
            if (response.status === 'success') {
                console.log(response)
                setValues(response.content.values)
            } else {
                console.log(response)
            }
        }

    }, [profileInfo])

    useEffect(() => {
        if (values) {
          setInit(true)
        }
    },[values])

    useEffect(() => {
        if (init) {
            setTimeout(() => {
                setVisible(true)
            }, 500);
        } else {
            setVisible(false)
        }
    },[init])



    const handleSpin = () => {
        const response = window.core.query({ cmd: 'fortuneWeheelSpin' })

        if (response.status === 'success') {
            setWin(response.content.win)
            console.log(response.content.win)
            if (soundPlayer.current) {
                let promise = soundPlayer.current.play()
    
                if (promise !== undefined) {
                    promise
                        .then(_ => {
                            soundPlayer.current.play()
                        })
                        .catch(error => {
                            //dispatch(getAudioPlayer(true))
                        })
                }
            }
        } else {
			console.log(response)
		}
        
    }

    const handleSoundClick = () => {
        setSound(!sound)
    }

    const handleClose = () => {
        setInit(false)
        setValues(null)
        setIsFreespins(null)
        setWin(null)
    }

	if (init) {
        return (
            <div className="WheelFrame" data-visible={ visible }>
                <div className="controls">
                    <div className='Button ButtonSound' data-mute={ !sound } onClick={ handleSoundClick }></div>
                    <ButtonClose action={ handleClose } />
                </div>
                
                <Wheel 
                    values={ values }
                    win={ win }
                    isFreespins={ isFreespins }
                    onClick={ handleSpin }
                />
    
                <audio ref={ soundPlayer } muted={ !sound }>
                    <source src={ audio } type="audio/mp3" />
                </audio>
                
            </div>
        )
    } else {
        return <></>
    }
    
}