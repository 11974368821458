import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './SmsForm.scss'
import { getAuthorization, getAuthForm, setAudioEvent, getPopup } from '@bets/components/redux/actions'
import { Input } from '@bets/components'
import { Button } from '@bets/elements'


export const SmsForm = ({ visible = true, phone, password }) => {

  const dispatch = useDispatch()

  const settings = useSelector(state => state.settings)
  const words = useSelector(state => state.words)

  const [formData, setFormData] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  window.core.functions.onQueryError = (data, response) => {
    data.cmd === "casinoActivate" && setError(response.error)   
  }

  const confirmRegistration = event => {
    event.preventDefault()
    setLoading(true)

    let options = {
      cmd: "casinoActivate",
      domain: window.location.hostname,
      code: formData.code,
      phone: phone,
    }
    
    if (!options.code) {
      setError('sms code')
      return undefined;
    }
    
    let response = window.core.query(options)

    if (response.status === "success") {
      setTimeout(function () {
          setLoading(false)
          window.core.userLogin(login, formData.password, 0, 0)
      }, 200)
      window.core.userLogin(phone, password, 0, 0)
    } else {
      setLoading(false)
      setError(response.error)
      
    }
  }

  const getFormVal = (type, value) => {
    setFormData({ ...formData, [type]: value })
  }

  const getValidate = () => {
    if (!settings.validate) return false
    if (formData.code && formData.code !== '') {
      return false
    } else {
      return true
    }
  }

  const handleSms = event => {
    setError(false)
    getFormVal('code', event.target.value)
  }

  return (
    <form className="form Sms" data-vidible={ visible } onSubmit={ confirmRegistration }>

      { settings.info && <p className='info'>{ words.server.confirm_phone }</p> }

      <Input 
        type="text"
        placeholder={ words.server.confirm_code } 
        onChange={ handleSms } />
      
      <Button 
          className='submit-button' 
          loading={ loading } 
          disabled={ getValidate() }>
          { words.server.confirm }
      </Button>
      { error && <div className="error">{ error }</div>}
    </form>
  )
}