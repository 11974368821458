import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './SignInForm.scss'
import { Input } from '@bets/components'
import { SmsForm } from '@bets/components/Auth'
import { PhoneInput, Button, InputPassword, CurrencySelect, AcceptTerms } from '@bets/elements'

import { useGoogleReCaptcha } from "react-google-recaptcha-v3"


export const SignInForm = ({ visible = true, info = false}) => {

  const { executeRecaptcha } = useGoogleReCaptcha()

  const words = useSelector(state => state.words)
  const promo = useSelector(state => state.handling.promo)
  const beforeAuthorize = useSelector(state => state.profile.beforeAuthorize)
  const settings = useSelector(state => state.settings)

  const [formData, setFormData] = useState({})
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState(false)
  const [error, setError] = useState(false)
  const [activate, setActivate] = useState(false)

  
  window.core.functions.onQueryError = (data, response) => {
    data.cmd === "casinoRegister" && setError(response.error)   
  }

  useEffect(() => {

    if ( settings.registrationData.currencies.length === 1) {
      getFormVal('currency', settings.registrationData.currencies[0])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return;
    }

    const token = await executeRecaptcha('submit')

    token && signin(token)
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executeRecaptcha][formData])

  const handleSubmit = (e) => {
    e.preventDefault()

    setLoading(true)
    handleReCaptchaVerify()

  }

  const signin = (token) => {
    let options = {
      cmd: "casinoRegister",
      domain: window.location.hostname,
      password: formData.password,
      captcha: token,
      currency: formData.currency
    }

    if (settings.registrationData.promo) {
      if (promo) {
        options.promo = promo
      } else {
        options.promo = formData.promo
      }
    }
  
    if (type === 'phone' && settings.registrationData.phone && formData.phone !== '' && formData.phone) {
      options.phone = formData.phone
      options.RB = "phone";
    }

    if (type === 'email' && settings.registrationData.email && formData.email && formData.email !== '') {
      options.e_mail = formData.email
      options.RB = "e_mail";
    }

    if (settings.registrationData.name) {
      options.name = formData.name
    }
    
    let response = window.core.query(options)

    if (response.status === "success") {
      if (options.RB === "phone") {
        setActivate( true )
      } else {
        setTimeout(function () {
          window.core.userLogin(options.e_mail, formData.password, 0, 0)
      }, 1000)
      }
    } else {
      setError(response.error)
    }
    setLoading(false);
  }

  
  const getFormVal = (type, value) => {
    setError(false)
    setFormData({ ...formData, [type]: value })
  }

  const codeChange = (isvalid, value) => {
    setError(false)
    getFormVal('dialCode', value.dialCode)
  }

  const numberChange = (isvalid, value, event) => {
    setError(false)
    getFormVal('phone', event.dialCode + value)
    let type = value && value.length > 0 && value !== '' ? 'phone' : false
    setType(type)
  }

  const emailChange = event => {
    setError(false)
    let value = event.target.value
    getFormVal('email', value)
    let type = value && value.length > 0 && value !== '' ? 'email' : false
    setType(type)
  }

  const handlePassword = event => getFormVal('password', event.target.value)
  const handleName = event => getFormVal('name', event.target.value)
  const handlePromo = event => getFormVal('promo', event.target.value)
  const handleTerms = event => getFormVal('terms', event.currentTarget.checked) 
  const handleCurrency = event => getFormVal('currency', event.currentTarget.value)

  const getValidate = () => {
    if (!settings.validate) return false
    let valid = (!error && (formData.terms && formData.terms !== false) && (formData.currency && formData.currency !== '0') && formData.password !== '' && ((formData.email && formData.email !== '') || (formData.phone && formData.phone !== ''))) ? false : true
    return valid
  }

  let or = settings.registrationData.phone && settings.registrationData.email

  if (activate === false) return (
    <form className="Signin form" data-visible={ visible } onSubmit={ (e) => handleSubmit(e) }>   

      { settings.registrationData.phone && 
          <PhoneInput 
            onChange={ numberChange } 
            onChangeCountry={ codeChange } 
            disabled={ type === 'email' }
          />
      }

      { or && <div className="or"><span>{ words.server.or }</span></div> }

      { settings.registrationData.email && 
          <Input 
            type="mail"
            disabled={ type === 'phone'} 
            placeholder={ words.server.email } 
            onChange={ emailChange } />
      }

      { settings.registrationData.name && 
          <Input 
            type="text"
            placeholder={ words.server.fio } 
            onChange={ handleName } />
      }

      <InputPassword onChange={ handlePassword }/>

      { (settings.registrationData.promo && !promo) && 
          <Input 
            type="text"
            placeholder={ words.server.promo + ' (' + (settings.registrationData.promo !== 'required' ? words.server.optional : words.server.required) + ')' } 
            onChange={ handlePromo } />
      }
      
      <div className="row">
          <div className="auth__form-select-container">
            <CurrencySelect onChange={ handleCurrency }/>
          </div>
      </div>

      <AcceptTerms onChange={ handleTerms }/>

      <Button 
          className='submit-button' 
          loading={ loading } 
          disabled={ getValidate() }>
          { words.server.registration }
      </Button>

      { error && <div className="error">{error}</div>}

      { info && 
        <div className='info'>
            <p dangerouslySetInnerHTML={{ __html: info }}></p>
        </div> 
      }
    </form>  
  )

  if (activate === true) return (
    <SmsForm phone={ formData.phone } password={ formData.password }/>
  )
}