import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getPopup } from '@bets/components/redux/actions'

import './Terms.scss'

export const Terms = () => {

	const dispatch = useDispatch()
	const words = useSelector(state => state.words)

	return (
		<div className='Terms'>
			<div className="wrapper">
				<div className="window">
					<div className="close-button Button ButtonClose" onClick={ () => dispatch( getPopup({ active: false, name: ''})) }></div>
					<div className="content" dangerouslySetInnerHTML={{ __html: words.local.the_terms_and_conditions }} />
				</div>
			</div>
		</div>
	)
}