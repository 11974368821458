import React, {useRef, useEffect, useMemo, forwardRef, useImperativeHandle, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux';
import { setPreloader } from '@bets/components/redux/actions';

import PropTypes from 'prop-types';
import {FixedSizeList as List} from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { GameCard } from 'components';
import _ from "lodash"

import './Grid.scss';

export const Grid = forwardRef(({games, remember = false}, ref) => {

    const dispatch = useDispatch();
    const size = useSelector(state => state.handling.size);
    const words = useSelector(state => state.words);
    const preloader = useSelector(state => state.handling.preloader);

    const activeProvider = useSelector(state => state.handling.activeGameList)
    const gridRef = useRef();
    const gamesArr = games
    const itemCount = gamesArr.length;
    const layout = size.landscape ? 'horizontal' : 'vertical';
    const [columnCount, setColumnCount] = useState(2)
    const rowCount = Math.ceil(itemCount / columnCount);
    const initialScrollOffset = parseInt(window.localStorage.getItem('lastScrollPosition')) || 0;
    const debouncedRender = _.debounce(index => { window.localStorage.setItem('currentIndex', index)}, 800);
    const debouncedScroll = _.debounce( offset => offset && window.localStorage.setItem('lastScrollPosition', offset), 500);
    
    const [itemSize, setItemSize] = useState(160)
    const [slider, setSlider] = useState(null)

    useEffect(() => {
        if (size.landscape === true) {
            setItemSize(size.height / 2)
            
            if (size.height <= 450) {
                setColumnCount(2)
                setItemSize(size.height / 2.1)
            }
            
            if (size.height > 450 && size.height <= 550) {
                setColumnCount(3)
                setItemSize(size.height / 3 )
            }
    
            if (size.height > 550) {
                setColumnCount(4)
                setItemSize(size.height / 3.7)
            }
        }
    
        if (size.landscape === false) {
            setItemSize(size.width / 3)
        }
    }, [size])

    const itemData = useMemo(
        () => ({
            columnCount,
            itemCount,
            gamesArr,
        }),
        [columnCount, itemCount, gamesArr]
    );
    
    const getScrollPosition = () => {
        return gridRef.current ? gridRef.current.state.scrollOffset : 0;
    }

    useImperativeHandle(ref, () => ({
        getScrollPosition,
    }));

    let emptyText = '';

    if (activeProvider === 'favorite_games') {
        emptyText = words.server.please_add_games_to_favorite
    } else if (activeProvider === 'last_games') {
        emptyText = words.server.last_games_is_empty
    } else if (activeProvider === 'search') {
        emptyText = 'Games not found'
    }

    useEffect(() => {
        if (gridRef && gridRef.current) {
            gridRef.current.scrollToItem(0);
        }
    }, [activeProvider]);

    useEffect(() => { 
        if(games && games.length > 0) {
            
            setSlider(<AutoSizer>
                {({ height, width }) => (
                    <List
                        className="games-grid"
                        ref={gridRef}
                        height={height}
                        itemCount={rowCount}
                        itemSize={itemSize}
                        width={width}
                        itemData={itemData}
                        layout={layout}
                        onScroll={(e) => remember && debouncedScroll(e.scrollOffset)}
                        onItemsRendered={({visibleStartIndex}) => {

                           preloader && dispatch( setPreloader(false) )
                           remember && debouncedRender(visibleStartIndex)
                        }}
                        initialScrollOffset={initialScrollOffset}
                    >
                        {
                            ({ data, index, style }) => {
                              
                                const {columnCount, itemCount, gamesArr} = data;
                                const startIndex = index * columnCount;
                                const stopIndex = Math.min(itemCount - 1, startIndex + columnCount - 1);
                        
                                const games = [];
                                for (let i = startIndex; i <= stopIndex; i++) {
                                    let game = gamesArr[i];
                        
                                    let img;
                                    if (game) {
                                        img = game.img.replace('game_img', 'game_img_4');
                                    }

                                    if (i === itemCount - 1 && size.landscape) {
                                        img = img.replace('game_img_4', 'game_img_5');
                                    }
                        
                                    games.push(
                                        <GameCard
                                            key={game.id}
                                            id={game.id}
                                            name={game.name}
                                            label={game.label}
                                            img={img}
                                            demo={false}
                                            fs={game.fs}
                                            lazy={ false }
                                        />
                                    );
                                }
                        
                                return (
                                    <div className="games-grid__item" style={style}>
                                        {games}
                                    </div>
                                );
                            }
                        }
                    </List>
                )}
            </AutoSizer>)
        } else {
            dispatch(setPreloader(false))
            setSlider(null)
        }
        
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [games, size, itemSize])


    useEffect(() => {
        if (gridRef && gridRef.current) {
            let storageIndex = window.localStorage.getItem('currentIndex')
            storageIndex !== undefined && gridRef.current.scrollToItem(storageIndex);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [size.landscape]);

    return (
        <>
            {slider !== null ? (
                slider
                ) : (
                    <div className='empty'>
                        <p className="text">
                            { emptyText }
                            { () => dispatch(setPreloader(false)) }
                        </p>
                    </div>
                )
            }
        </>
    )
});

Grid.propTypes = {
    games: PropTypes.array,
}

Grid.defaultProps = {
    games: [],
}
