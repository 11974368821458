import React from 'react';

/**
 * for get actual state in callback fn
 * in dynamically created components
 */
export const useFunction = (callback) => {
    const ref = React.useRef();
    ref.current = callback;
  
    return React.useCallback(function() {
      const callback = ref.current;
      if (typeof callback === "function") {
        return callback.apply(this, arguments);
      }
    }, []);
  }