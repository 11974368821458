import React from 'react'
import { useSelector } from 'react-redux'
import { PromoForm } from '@bets/components'
import { Progress } from '@bets/elements'
import CompoundTimer from 'react-compound-timer'
import classNames from 'classnames';

import './balance.scss'

export const Balance = () => {

	const words = useSelector(state => state.words)
	const profileInfo = useSelector(state => state.profile.info)
	const bonuses = useSelector(state => state.profile.info.bonuses)

	const renderBonusesText = lines => (
		lines.map((line, index) => {
			if (line.tag === 'timer') {
				return (
					<CompoundTimer
						initialTime={line.value * 1000}
						direction="backward"
						key={'timer-' + index}
						formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
					>
						{() => (
							<>
								<CompoundTimer.Days formatValue={(value) => `${value} days `}/>
								<CompoundTimer.Hours />:
								<CompoundTimer.Minutes />:
								<CompoundTimer.Seconds />
							</>
						)}
					</CompoundTimer>
				)
			}

			if (line.tag === 'oneinput') {
				return <PromoForm attributes={line.attributes} key={'promo-' + index} />
			}

			if (line.tag === 'progress') {
				return <Progress value={line.attributes.value} key={'progress-' + index} max={line.attributes.max} showValue={false} />
			}

			if (line.tag === 'br') {
				return <br key={'br-' + index} />
			}

			return React.createElement(line.tag, { ...line.attributes, key: index }, line.value)
		})
	)

	const renderBonuses = bonuses => {

		let arr = Object.values(bonuses)

		let items = arr.map(bonus => {
			let main = false, additional = false

			if (bonus.main) {
				main = Object.values(bonus.main).map((line, index) => {
					let name = Object.keys(bonus.main)[index]
					return <div key={index} className={name + ' text'}>{renderBonusesText(line)}</div>
				})
			}
			if (bonus.additional) {
				additional = Object.values(bonus.additional).map((line, index) => {
					return <div className='text' key={index}>{renderBonusesText(line)}</div>
				})
				//additional = renderBonusesText( Object.values(bonus.additional)[0] )
			}

			return <div className={classNames('bonus-item', `${bonus.name}`)} key={bonus.name}>
				{bonus.name === 'happy_hours' && <img className='present-image' src='images/present.png' alt='Present' />}
				{main && main}
				{additional &&
					<span>{additional} </span>
				}
			</div>
		})

		return items
	}

	return (
		<div className={classNames('tab', 'tab--balance')}>

			{profileInfo.name && <div className="row">
				<div className="title">{words.server.fio}</div>
				<div className="value">{profileInfo.name}</div>
			</div>}

			<div className="row">
				<div className="title">{words.server.lo_gin}</div>
				<div className="value">{profileInfo.login}</div>
				<div className="edit"></div>
			</div>

			<div className="row">
				<div className="title">{words.server.balance}</div>
				<div className="value">{profileInfo.cash} {profileInfo.currency}</div>
				<div className="edit"></div>
			</div>

			{bonuses.wager &&
				<div className="row">
					<div className="title">{profileInfo.words.chargeable_balance}</div>
					<div className="value">{profileInfo.wager.chargeable} {profileInfo.currency}</div>
				</div>
			}



			<div className="information">
				<h4>{profileInfo.words.information}</h4>

				<div className="bonuses-info">
					{bonuses && renderBonuses(bonuses)}
				</div>
			</div>

		</div>
	)
}