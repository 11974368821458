import React from 'react'
import { getPopup } from '@bets/components/redux/actions'
import { useSelector, useDispatch } from 'react-redux'
import { Checkbox } from '@bets/elements'
import './AcceptTerms.scss'

export const AcceptTerms = ({ onChange }) => {

    const dispatch = useDispatch()
    const words = useSelector(state => state.words)

    return (
        <div className="AcceptTerms">

          <Checkbox id='terms' name='terms' onChange={ onChange }/>
          
          <div className="signInTerms">
            <label htmlFor="terms">{ words.server.i_turned_18 }</label>
                <span className="termsHref" onClick={ () => dispatch( getPopup({ active: true, name: 'terms'})) }>
                { words.server.read_here }
                </span>
          </div>

        </div>
    )
}