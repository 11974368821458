import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFavoritGames } from '@bets/components/redux/actions'

import './ButtonFavorite.scss'
import { formatUrlVersion } from '@bets/features'

const getFavorite = id => {
    if (localStorage.getItem("Favorite") ) {
        const favoriteList = localStorage.getItem("Favorite").split(",")
        const result = favoriteList.filter( elem => elem === id)
        return result.length > 0
    } else return false
}

export const ButtonFavorite = ({ id }) => {

    const dispatch = useDispatch()
    const [active, setActive] = useState(getFavorite(id) ? true : false)

    const allGames = useSelector(state => state.games.gameListAll)

    const setFavorite = (id) => {

        let list = localStorage.getItem("Favorite") ? localStorage.getItem("Favorite").split(",") : []
        if (!active) {
			list.push(id)
			list = list.join()
			localStorage.setItem("Favorite", list)
        } else {
            list = list.filter( item => item !== id).join()
			localStorage.setItem("Favorite", list)
			dispatch( getFavoritGames({ storage: list.split(","), gameList: allGames }) )
        }
        setActive(!active)
	}

    return (
        <div className='ButtonFavorite' id={ id } data-active={ active } onClick={ () => setFavorite(id) }>
            <img src={formatUrlVersion("images/icons/favorite.svg")} alt="Favorite" />
        </div>
    )
}