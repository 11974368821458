import React from 'react'
import { useSelector } from 'react-redux'

import './ChatButton.scss'

export const ChatButton = ({ onClick }) => {

	const preloader = useSelector(state => state.handling.preloader)

	return (
		<div className="ChatButton" data-visible={ !preloader } onClick={ onClick }>
			<svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 25 20"><path fill="#fff" d="M22.5 5l-10 6.25L2.5 5V2.5l10 6.25 10-6.25V5zm0-5h-20A2.49 2.49 0 0 0 0 2.5v15A2.5 2.5 0 0 0 2.5 20h20a2.5 2.5 0 0 0 2.5-2.5v-15A2.5 2.5 0 0 0 22.5 0z"/></svg>
		</div>
	)
}