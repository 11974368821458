import React from 'react'

import './Progress.scss'

export const Progress = ({ value, max, showValue = true }) => {

    return (
        <div className="progress">
            <progress max={ max } value={ value } />
            { showValue && <span className="value">{ value } / { max }</span>}
        </div>
    )
}