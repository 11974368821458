import React, { useState, useEffect } from 'react'
import { setActiveGameList, getPopup, setGameFullscreen } from '@bets/components/redux/actions'
import { useSelector, useDispatch } from 'react-redux'
import './Categories.scss'

export const Categories = () => {

	const dispatch = useDispatch()
	const words = useSelector(state => state.words)
	const authorized = useSelector(state => state.profile.authorized)
	const menuSections = useSelector(state => state.settings.menuSections)
	const gameCode = useSelector(state => state.game.code)
	const activeGameList = useSelector(state => state.handling.activeGameList)
	const games = useSelector(state => state.games.views)

	const [buttons, setButtons] = useState(null)

	useEffect(() => {
        if (menuSections.length > 0) {
			let filteredButtons = filterButtons(menuSections)
            setButtons(renderButtons(filteredButtons))
        }
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuSections, games, activeGameList])

	const getLabelFromWords = name => {
		switch (name) {
			case 'sport-betting':
				return words.server['sport']
			case 'live-dealers':
				return words.server['dealers']
			case 'fast_games':
				return words.server['tv_games']
			default:
				return words.server[name] ? words.server[name] : name.toLowerCase().replace('-', ' ').replace('games', '').replace('betting', '').replace('dealers', '')
		}
	}
	
	const handleClick = (name, value) => {
		if (name !== 'sport-betting') {
			window.core.closeGame()
		} else {
			dispatch(setGameFullscreen(false))
		}

		if (value && typeof value === 'number') {
			authorized ? window.core.openGame(value, '', 1, 0) : dispatch( getPopup({ active: true, name: 'auth' }) )
		} else if (name === 'jackpots') {
			dispatch( getPopup({ active: true, name: name }) )
		} else {
			dispatch( setActiveGameList(name) )
		}
	}

	const filterButtons = items => (
		items.filter( item => {
			let notGameList = ['jackpots', 'bonuses', 'deposit', 'tournament']
			let key = Object.keys(item)[0]
			let value = Object.values(item)[0]
			let byEnable = value !== false // проверка включено ли в settings
			let byGameList = !notGameList.includes(key) ? games[key] && games[key].length > 0 : true
			return byEnable && byGameList
		})
	)

	const renderButtons = items => {
		return items.map( item => {
			let name = Object.keys(item)[0]
			let value = Object.values(item)[0]
			return <div key={ name } data-active={ (name === activeGameList && !gameCode) ? true : false } className="item" onClick={ () => handleClick(name, value) } data-type={ name }>
				<div className="icon" style={{ backgroundImage: `url(./images/categories/${ name }.png)`}}></div>
				<span className="label">{ getLabelFromWords(name) }</span>
			</div>
		})
	}

	if (buttons) return <div className="categories">{ buttons }</div>
	return <></>
}