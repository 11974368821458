import React, { useMemo } from 'react'
import classNames from "classnames";
import PropTypes from 'prop-types';

import { Item } from './Item';

import './List.scss';

export const List = ({ jackpots, type, hiding, theme }) => {
  const jackpotsRenderedList = [];
  const renderElements = useMemo(() => (jackpots || window.core.jackpots || [])
    .map(jackpot => {
      const { name } = jackpot;

      if (!jackpotsRenderedList.includes(name)) {
        jackpotsRenderedList.push(name);

        return <Item key={`JackpotItem-${name}`} jackpot={jackpot} theme={theme} />
      }
    }), [jackpots, window.core.jackpots])

  if ((jackpots && jackpots.length > 0) || (window.core.jackpots && window.core.jackpots.length > 0)) {
    return (
      <div className={classNames('spin-jackpots-list', {
        'spin-jackpots-list-hiding': hiding,
        'spin-jackpots-list-royal': theme === 'royal'
      })} data-type={type} >
        {renderElements}
      </div >
    )
  } else {
    return <></>
  }
}

List.propTypes = {
  jackpot: PropTypes.any,
  hiding: PropTypes.bool,
  theme: PropTypes.oneOf(['royal', 'default'])
}

List.defaultProps = {
  jackpot: undefined,
  hiding: false,
  theme: 'default'
}
