import { ButtonClose } from '@bets/elements'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getPopup, setPntz } from '@bets/components/redux/actions'
import './Pntz.scss'


export const Pntz = ({ domainKey }) => {

    const dispatch = useDispatch()
    const authorized = useSelector(state => state.profile.authorized)


    useEffect(() => {
        if (authorized) {
            const TOKEN_KEY = 'token';
            const IFRAME_NODE_ID = 'player-wallet';
            const IFRAME_WIDTH = '100%';
            const IFRAME_HEIGHT = '100%';
            const script = document.createElement("script");
            script.src = "https://pntz.club/js/player.js?rnd=RAND_NUMBER"
            script.async = true
            script.onload = (e) => { initPntzWallet(domainKey, TOKEN_KEY, IFRAME_NODE_ID, IFRAME_WIDTH, IFRAME_HEIGHT) }
            document.body.appendChild(script)
        }
    }, [authorized])

	return (
        <div className='Pntz'>
            <ButtonClose action={ () => dispatch( getPopup({ active: false, name: '' }))}/>
            <div id="player-wallet"></div>
        </div>
	)
}