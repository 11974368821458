import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveGameList } from "@bets/components/redux/actions";
import { Dropdown } from "@bets/components";
import { CategoriesSlider } from "./CategoriesSlider/CategoriesSlider";
import { PaginationSlider } from "./PaginationSlider/PaginationSlider";
import { ProvidersSlider } from "./ProvidersSlider/ProvidersSlider";
// import { SliderVertical} from "./SliderVertical/SliderVertical";
import { Grid } from "./Grid";

import "./Games.scss";

export const Games = ({ visible }) => {
  const dispatch = useDispatch();
  const wrapperRef = useRef();
  const grid = useRef();
  // const slider = useRef();

  const words = useSelector((state) => state.words);

  const size = useSelector((state) => state.handling.size);
  const views = useSelector((state) => state.games.views);
  const activeProvider = useSelector((state) => state.handling.activeGameList);
  const providers = useSelector((state) => state.games.providers);
  const types = useSelector((state) => state.games.types);
  const settings = useSelector((state) => state.settings);
  const loaded = useSelector((state) => state.games.loaded);

  const preloader = useSelector((state) => state.handling.preloader);
  const [activeView, setActiveView] = useState(false);
  const [sortedGames, setSortedGames] = useState(null);
  const [showPagination, setShowPagination] = useState(false);
  const categoryBtn = document.querySelector(".category.category-providers");
  const [eventCategoryBtn, setEventCategoryBtn] = useState(false);
  const [btnVisible, setBtnVisible] = useState(false);
  const [btnVisibleAll, setBtnVisibleAll] = useState(false);
  const typesNotAll = types.filter((type) => type !== "all" && views[type].length > 0).slice(0, 20);


  useEffect(() => {
    if (activeProvider && views && views.all.length > 0) {
      let view = views[activeProvider].filter((game) => game);
      if (settings.gamesBeforeAll) {
        view = sortGames(view);
      }
      setActiveView(view);
      if (settings.gamesBeforeAll) {
        setSortedGames(sortGames(views.all));
      } else {
        setSortedGames(views.all.filter((game) => game));
      }
      settings.rememberState && window.localStorage.setItem("lastProvider", activeProvider);
    }
  }, [activeProvider, views, settings]);

  const sortGames = (games) => {
    let ids = settings.gamesBeforeAll;
    let sorted = games.filter((game) => game);
    let beforeItems = [];
    ids.forEach((item) => {
      let find = sorted.findIndex((game) => game.id === item);

      if (find !== -1) {
        let item = sorted[find];
        beforeItems.push(item);
        sorted.splice(find, 1);
      }
    });

    return beforeItems.concat(sorted);
  };

  const backToProvider = () => {
    setEventCategoryBtn(!eventCategoryBtn);
    setShowPagination(!showPagination);
    setBtnVisible(!btnVisible);
    setBtnVisibleAll(!btnVisibleAll);
  };

  const handleAllGames = () => {
    setSortedGames(views.all);
    setBtnVisible(!btnVisible);
    setEventCategoryBtn(!eventCategoryBtn);
    setShowPagination(!showPagination);
    setBtnVisibleAll(!btnVisibleAll);
  };

  const permutationGamesList = (item) => {
    const providerData = item.target.alt;
    const providerName = providers.find((provider) => provider.name === providerData);
    const providerNameNormalized = providerName.label
      .toLowerCase()
      .replace(" ", "_")
      .replace("sport_betting", "live_betting")
      .replace("novomatiс", "novomatic")
      .replace("flg", "fast_games");

    if (!views[providerNameNormalized]) {
      return;
    } else {
      setSortedGames(views[providerNameNormalized]);
      setEventCategoryBtn(!eventCategoryBtn);
      setBtnVisible(!btnVisible);
      setBtnVisibleAll(!btnVisibleAll);
    }
  };

  return (
    <>
      {size.mobile && (
        <div className="filter">
          <Dropdown
            placeholder={words.server.categories}
            type="types"
            list={types}
            activeItem={activeProvider}
            onClick={(style) => dispatch(setActiveGameList(style))}
          />
          <Dropdown
            placeholder={words.server.providers}
            type="providers"
            list={providers}
            activeItem={activeProvider}
            onClick={(provider) => dispatch(setActiveGameList(provider))}
          />
        </div>
      )}
      <div className={"games " + (size.mobile ? " mobile" : "")} data-visible={(visible && !preloader) ? true : false}>
        <div className="games-wrapper" ref={wrapperRef}>
          {activeView && wrapperRef && wrapperRef.current && size.mobile ? (
            <Grid games={activeView} remember={settings.rememberState} ref={grid} />
          ) : (
            <>
              <CategoriesSlider
                categories={typesNotAll}
                onClick={backToProvider}
                visibleBack={btnVisible}
                onClickAll={handleAllGames}
                visibleAll={!btnVisibleAll}
              />
              {!eventCategoryBtn && (
                <ProvidersSlider
                  providerList={providers.map((provider) => provider.name)}
                  onClickProvider={permutationGamesList}
                  setInitialSlider={activeProvider}
                />
              )}
              {sortedGames && eventCategoryBtn && (
                <PaginationSlider games={sortedGames} remember={settings.rememberState} />
              )}
            </>
          )}
          {/* <SliderVertical games={activeView} remember={settings.rememberState} sliderWrapRef={wrapperRef} ref={slider} /> */}
        </div>
      </div>
    </>
  );
};
