import React, { useEffect } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import './PaymentSystems.scss'

export const PaymentSystems = ({ onSelect, manual = false, systems, selected, children }) => {

	
	useEffect(() => {
        manual && onSelect('manual', 'manual', 'manual')
    }, [])

	const renderPaymentSystems = systems => ( 
		systems.map( system => {
				let key = system.system + '_'  + system.provider.toString(),
				source = `./resources/sitepics/lobby/payments_systems_react/${ key }.png`,
				formatName = system.name.split(' ').slice(1).join(' ')
				//console.log(formatName)
				//console.log(key)
				//console.log(system.currency)
			return (
				<div className="payment-system" key={ key } data-select={ key === selected ? true : false } onClick={ () => handleSelect(system.system, system.provider, key, system.currency, system.fields) } >
					<LazyLoadImage alt={ system.name } effect="blur" src={ source } />
					{ system.name && <p className='full-name'>{ formatName ? formatName : system.name }</p>}
					{ system.currency && <p className='currency'>{ system.currency }</p>}
				</div>
			)
		})
	)

	const handleSelect = (system, provider, key, currency, fields) => {
		onSelect(system, provider, key, currency, fields)
	}

	return (
		<div className="PaymentSystems">
			{ children }
			{ manual && 
				<div className="payment-system" 
					data-select={ 'manual' === selected ? true : false } 
					onClick={ () => handleSelect('manual', 'manual', 'manual') }>
					{ manual.image && <img src={ manual.image } alt="Payment Method"/>}
					{ (!manual.image && manual.title) && <p>{ manual.title }</p>}
				</div> 
			}
			
			{ systems && renderPaymentSystems(systems) }
		</div>
	)
}