import React from 'react'

function getMonth(a) {
    if (a >= 10) {
      return `${a}`
    } else {
      return `0${a}`
    }
  }

export const getGamesHistory = () => {
    const date = new Date()
    const year = date.getFullYear()
    const month = getMonth(date.getMonth() + 1)
    const day = date.getDate()
    
    let response = window.core.query({
      cmd: "casinoGamesHistory",
      start: `${year}-${month}-${day} 00:00:00`,
      end: window.core.terminal
        ? window.core.terminal.datetime
        : `${year}-${month}-${day} 00:00:00`,
    })

    let history = []
    if (response.content) {
      response.content.map((item) => {
        if (!(history.indexOf(item.game_name) + 1)) {
          history.push(item.game_name)
        }
      })
    }
    return history
}