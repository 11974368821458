import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid'
import classNames from "classnames";
import { cacheImages } from '@bets/features';

import { imagesList } from './imagesList';

import { useFunction } from '../../hooks';


import './Coinfall.scss';

const randomIntFromInterval = (min, max) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}

const KIND_OF_UP_TO_DOWN = ['right', 'left', 'right-wide', 'left-wide']

export const Coinfall = ({ count, startLine, fallType, startRange, hiding, oneWaveDuration }) => {
    const [isLoadedImgs, setIsLoadedImgs] = useState(false);
    const [coins, setCoins] = useState([]);

    useEffect(() => {
        cacheImages(imagesList).finally(() => setIsLoadedImgs(true));
    }, []);

    useEffect(() => {
        const initCoins = [...Array(count)].map(() => createCoin());

        setCoins(initCoins);
    }, [count]);

    const animationEnd = useFunction((id) => {
        const filterCoins = coins.filter(coin => coin.key !== id);

        setCoins([...filterCoins, createCoin()]);
    });

    const createCoin = () => {
        const id = uuid();

        return <div
            key={id}
            onAnimationEnd={() => animationEnd(id)}
            className={classNames(
                'coinfall__coin',
                `coinfall__coin-${fallType}`,
                `coinfall__coin-${fallType}-${KIND_OF_UP_TO_DOWN[randomIntFromInterval(0, 3)]}`
            )}
            style={{
                top: `${startLine}%`,
                left: `${randomIntFromInterval(startRange.left, startRange.right)}vw`,
                animationDuration: `${!oneWaveDuration ? randomIntFromInterval(2, 3) / 6 : randomIntFromInterval(2, 3) / 3}s, 
                ${fallType === 'down' ? randomIntFromInterval(2, 7) / 2 : randomIntFromInterval(3, 5)}s`,
                animationDelay: `0s, 
                ${!oneWaveDuration ? randomIntFromInterval(0, 3) : randomIntFromInterval(2, oneWaveDuration - 3)}s`
            }}
        />
    }

    if (!isLoadedImgs) return null;

    return <div className={classNames('coinfall', {
        'coinfall-hiding': hiding
    })}>
        {coins}
    </div>
}

Coinfall.propTypes = {
    count: PropTypes.number,
    startLine: PropTypes.number, // top position in vh
    fallType: PropTypes.oneOf(['down', 'up-down', 'up']),
    startRange: PropTypes.shape({  // range from left to right in wv
        left: PropTypes.number,
        right: PropTypes.number
    }),
    hiding: PropTypes.bool,
    oneWaveDuration: PropTypes.number //duration one way in seconds
}

Coinfall.defaultProps = {
    count: 10,
    startLine: -10,
    fallType: 'down',
    startRange: { left: 0, right: 95 },
    hiding: false,
    oneWaveDuration: undefined
}