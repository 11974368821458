import {
  GET_GAMELIST,
  GET_FAVORITE,
  GET_ACTIVE_GAMELIST,
  GET_SEARCH,
  RESET_STORE
} from "../actions/actionTypes"

import { getGamesHistory } from '@bets/features'

//const formatTypes = list => list.map( item => ( { name: item, label: item.toLowerCase()}))

const initialState = {
  loaded: false,
  views: {
    all: [],
    search: [],
    top: [],
    new: [],
    providers: [],
    categories: [],
    favorite_games: [],
    last_games: []
  },
  providers: [],
  types: window.settings.types,
  categories: window.settings.categories
};

const findFavoriteGames = (gameList) => {
  let storage = localStorage.getItem("Favorite") ? localStorage.getItem("Favorite").split(",") : []
  return storage.map( item => gameList.find( game => game.id === item) )
}

const findHistoryGames = (gameList) => {
  let history = getGamesHistory()
  history = history.map( item => gameList.find( game => game.name === item) )
  return history
}

const findGames = (gameList, searchTerm) => {
  let validatePattern = /^[a-zа-яА-ЯA-Z0-9\s]+$/

  if (validatePattern.test(searchTerm)) {
    let term = new RegExp(`${searchTerm}`, 'i')
    return gameList.filter( game => (game.name.match(term)))
  } else {
    return []
  }
}

const addAllProvider = providers => {
  let newProviders = providers
  newProviders.unshift({ name: 'ALL', label: 'all' })
  return newProviders
}

const sortByCategories = (games, categories) => {

  let obj = {}

  categories.map( category => {
    let name = category.toLowerCase().split(' ').join('-')
    return obj[name] = games.filter( game => game.category === category)
  })

  return obj
}

const sortByProviders = (games, providers) => {
  let obj = {}

  providers.map( provider => {
    return obj[provider.label] = games.filter( game => game.label === provider.label)
  })

  return obj
}
const sortById = (games, ids) => (
  ids.map( id => games.filter( game => game.id === id)[0])
)

const sortByFreespins = gameList => {
  return gameList.filter( game => game.fs === true) 
}


export const gamesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVE_GAMELIST:
      if (action.payload === 'last_games') return {
        ...state, views: {
          ...state.views,
          last_games: findHistoryGames(state.views.all),
        },
      }

      if (action.payload === 'favorite_games') return {
        ...state, views: {
          ...state.views, favorite_games: findFavoriteGames(state.views.all),
        },
      };
      return state

    case GET_GAMELIST:
      return {
        ...state,
        loaded: true,
        providers: addAllProvider(action.payload.gameLabels.slice(3)),
        views: {
          all: action.payload.gameList,
          search: [],
          new: sortById( action.payload.gameList, action.payload.top.new ),
          top: sortById( action.payload.gameList, action.payload.top.top ),
          freespins: sortByFreespins(action.payload.gameList),
          ...sortByProviders( action.payload.gameList, action.payload.gameLabels.slice(3) ),
          ...sortByCategories( action.payload.gameList, state.categories ),
          last_games: findHistoryGames(action.payload.gameList),
          favorite_games: findFavoriteGames(action.payload.gameList)
        }
      };

    case GET_SEARCH:
      return {
        ...state, views: {
          ...state.views, search: findGames( state.views.all, action.payload ),
        },
      };
    case RESET_STORE:
      return initialState
    default:
      return state;
  }
}
