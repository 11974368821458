import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setActiveGameList, setSearch } from '@bets/components/redux/actions'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Mousewheel, Lazy, Virtual, Scrollbar } from 'swiper'

import './ProvidersBar.scss'
import 'swiper/swiper.scss'

SwiperCore.use([Navigation,Mousewheel, Lazy, Virtual, Scrollbar])

export const ProvidersBar = ({ nav = true, slidesPerView = 'auto' }) => {

	const dispatch = useDispatch()
	const activeGameList = useSelector(state => state.handling.activeGameList)
	const providers = useSelector(state => state.games.providers)
	const settings = useSelector(state => state.settings)
	const preloader = useSelector(state => state.handling.preloader)

	const navigation = nav ? nav : { prevEl: '.nav.prev', nextEl: '.nav.next' }

	const params = {
		slidesPerView: slidesPerView,
		shortSwipes: true,
		loop: true,
		navigation: navigation,
		breakpoints: {
			0: {
				slidesPerView: 3,
			},
			576: {
				slidesPerView: 5
			},
			768: {
				slidesPerView: 8,
			}
		},
		onSlideChange: swiper => settings.rememberState && window.localStorage.setItem('lastProviderSlide', swiper.realIndex)
	}

	const getInitialSlide = () => {
		let storageValue = window.localStorage.getItem('lastProviderSlide')
		if (settings.rememberState && storageValue !== null && storageValue !== undefined) {
			return parseInt(storageValue, 10)
		} else {
			return 0
		}
	}
	
	const renderSlides = providers => (
		providers.map( (item, index) => ( 
			<SwiperSlide 
				id={ item.label } 
				className={ item.label === activeGameList && 'active' } 
				key={ index } 
				onClick={ () => dispatch( setActiveGameList(item.label), dispatch( setSearch(false) ) ) }>
					<span className="label">{ item.label.replace('_', ' ').replace('betting', 'games') }</span>
					<span className="devider">|</span>
			</SwiperSlide>
		))
	)
	
	const renderSlider = providers => (
		<Swiper className='bar' initialSlide={ getInitialSlide() } { ...params }>
			{ renderSlides(providers) }
		</Swiper>
	)

	return (
		<div className='ProvidersBar' data-nav={ nav } data-visible={ preloader ? false : true}>
			{ nav && <div className="nav prev" />}
			<div className="bar-body">
				<div className="wrapper">
					{ (providers && providers.length > 0) && renderSlider(providers) }
				</div>
			</div>
			{ nav && <div className="nav next" />}
		</div>
	)    
}