import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useOutsideClick } from '@bets/hooks'

import './Dropdown.scss'

export const Dropdown = ({ placeholder, list, type = 'types', activeItem, onClick }) => {
  
  const ref = useRef()
  const words = useSelector(state => state.words)
  const preloader = useSelector(state => state.handling.preloader)
  const [opened, setOpened] = useState(false)

  const handleClick = label => {
      onClick(label)
      setOpened(!opened)
  }

  useOutsideClick(ref, () => setOpened(false), opened)

  const renderProviders = items => items.map( (item, index) => {
    return <li key={ index } 
        onClick={ () => handleClick(item.label) }>
        { item.label.replace('_', ' ').replace('betting', 'games') }
    </li>})
  
  const renderTypes = items => items.map( (item, index) => {
   
    return <li key={ index } 
        onClick={ () => handleClick(item) }>
        { words.server[item] }
    </li>})

  const renderActiveItem = items => {
    let name
    if(type === 'types') {
      name = items.find( item => item == activeItem && item !== 'all')
      name = words.server[name]
    } else {
      name = items.find( item => item.label == activeItem && item.label !== 'all')
      if (name !== undefined) {
        name = name.label.replace('_', ' ').replace('betting', 'games')
       }
    }
    
    if (name !== undefined) {
        return <span>{ name }</span>
    } else {
        return <span className="placeholder">{ placeholder }</span>
    }
  }

  return (
    <div className="dropdown" ref={ ref } data-opened={ opened } data-visible={ !preloader }>
      <div className="dropdown-body" onClick={ () => setOpened(!opened) }>

        <div className="dropdown-current">
            { list && renderActiveItem(list) }
        </div>
        <div className="dropdown-arrow"/>
      </div>

      <div className='dropdown-list'>
        <ul>{ type === 'types' ? renderTypes(list) : renderProviders(list)}</ul>
        
      </div>
      
    </div>
  )
}