import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSearch, setActiveGameList, setActiveInput } from '@bets/components/redux/actions'
import { useDebounce, useOutsideClick } from '@bets/hooks'

import './Search.scss'

export const Search = () => {

    const dispatch = useDispatch()
    const ref = useRef()

    const words = useSelector(state => state.words)
    const size = useSelector(state => state.handling.size)
    const activeGameList = useSelector(state => state.handling.activeGameList)
    const handling = useSelector(state => state.handling)


    const [lastProvider, setLastProvider] = useState(activeGameList)
    const [value, setValue] = useState('')
    const [opened, setOpened] = useState((size.mobile && size.landscape) ? true : false)
    const debouncedSearchTerm = useDebounce(value, 500)

    useEffect(() => {
        if (debouncedSearchTerm && debouncedSearchTerm != '') {
            dispatch( setSearch(debouncedSearchTerm))
            dispatch( setActiveGameList('search') )
        } else {
            dispatch( setActiveGameList(lastProvider) )
        }
    }, [debouncedSearchTerm])

    useEffect(() => {
        if (activeGameList && activeGameList != 'search') {
            setLastProvider(activeGameList)
            setValue('')
        }
    }, [activeGameList])

    useOutsideClick( ref, () => {
        if (!value && !(size.mobile && size.landscape) && !(handling.activeInput === 'search-input' && settings.virtualKeyboard && !handling.size.mobile)) {
            setOpened(false)
        }
    }, opened)

    const changeHandler = newValue =>  {
        if (newValue === '') {
            setValue('')
        } else {
            setValue( newValue )
            if (settings.virtualKeyboard && !handling.size.mobile) {
                window.VirtualKeyboard.setInput(newValue)
            }
        }
       
    }

    const clearTerm = () => {
        setValue('')
        if (settings.virtualKeyboard && !handling.size.mobile) {
            window.VirtualKeyboard.setInput('')
        }
        setOpened(false)
        ref.current.value = ''
        let event = new Event('input', { bubbles: true});
	    ref.current.dispatchEvent(event);
    }

    return (
        <div className="Search">
            <input
                ref={ ref }
                id={'search-input'}
                className="input search-input"
                data-open={ opened }
                onClick={ () => setOpened(true) }
                placeholder={ words.server.search } 
                type="text" 
                value={ value } 
                
                onFocus={ (e) => {
                    dispatch( setActiveInput('search-input') ) 
                }}

                onInput={ event => changeHandler(event.target.value) }
            />
            <div className="clear" onClick={ () => clearTerm() } data-visible={ value ? true : false }/>
        </div>
    )
}