// promise, return true after load all img
export const cacheImages = async (imgs) => {
    const promises = await imgs?.map(src => {
        return new Promise(function (resolve, reject) {
            const img = new Image();

            img.src = src;
            img.onload = resolve();
            img.onerror = reject();
        });
    });

    await Promise.all(promises);

    return true;
}