import React, { useState, useEffect, createContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive'

import { isSmall, isMedium, isLarge, isExtraLarge, isPortrait } from '@bets/consts';

import { List as JackpotList } from './List/List';
import { Spin } from './Spin/Spin';
import { Complete as JackpotComplete } from './Complete/Complete';
import { Coinfall, BackCounter } from '@bets/components'
import { cacheImages } from '@bets/features';
import classNames from "classnames";

import {
    commonImgList, smImgList, mdImgList, lgImgList, xlgImgList
} from './imagesList';

import './JackpotSpin.scss';

const PAGE_NAME = {
    intro: 'intro',
    сountdown: 'сountdown',
    spin: 'spin',
    complete: 'complete'
};

import gongSrc from './assets/gong_start.mp3';
import { ButtonClose } from '@bets/elements';
const audio = new Audio(gongSrc);
audio.type = "audio/mp3";
audio.volume = 0.5;

export const JackpotSpinContext = createContext();

export const JackpotSpin = ({ skipCountdown, startPage, handleComplete, theme, allJackpots }) => {
    const [isLoadedImgs, setIsLoadedImgs] = useState(false);
    const [isLoadedContent, setIsLoadedContent] = useState(false);
    const [showContent, setShowContent] = useState(false);

    const [hidingPage, setHidingPage] = useState(null); // PAGE_NAME
    const [openPage, setOpenPage] = useState(startPage); // PAGE_NAME
    const [winJackpot, setWinJackpot] = useState(null);

    const isSMDevice = useMediaQuery(isSmall);
    const isMDDevice = useMediaQuery(isMedium);
    const isLGDevice = useMediaQuery(isLarge);
    const isXLGDevice = useMediaQuery(isExtraLarge);
    const isPortraitDevice = useMediaQuery(isPortrait);

    const jackpots = allJackpots ? allJackpots : useSelector(state => state.profile.info.jackpots);
    const userId = useSelector(state => state.profile.info.id);
    const size = useSelector(state => state.handling.size);

    useEffect(() => {
        // preload img for cache

        if (theme === 'royal') setIsLoadedImgs(true);

        if (isXLGDevice) {
            cacheImages([...commonImgList, ...xlgImgList]).finally(() => setIsLoadedImgs(true));
        } else if (isLGDevice) {
            cacheImages([...commonImgList, ...lgImgList]).finally(() => setIsLoadedImgs(true));
        } else if (isMDDevice) {
            cacheImages([...commonImgList, ...mdImgList]).finally(() => setIsLoadedImgs(true));
        } else if (isSMDevice) {
            cacheImages([...commonImgList, ...smImgList]).finally(() => setIsLoadedImgs(true));
        }

        return () => {
            audio.pause();
            audio.currentTime = 0;
        };
    }, []);

    useEffect(() => {
        if (!isLoadedContent && jackpots?.length > 0) {
            setIsLoadedContent(true);

            //test emul jackpot
            /*setTimeout(() => {
                setWinJackpot(jackpots[4]);
                setHidingPage(PAGE_NAME.intro);
            }, 8000);*/
        }
    }, [jackpots])

    useEffect(() => {
        if (winJackpot || !jackpots?.length) return;

        const storateWinIds = JSON.parse(localStorage.getItem('jackpotLastWinIds'));
        const jackpotLastWinIds = Array.isArray(storateWinIds) ? storateWinIds : [];

        let findWinJackpot = jackpots?.find(j => (j.user === userId)
            || (j.win && !jackpotLastWinIds.includes(`${j.id}`)));

        if (findWinJackpot) {
            if (findWinJackpot.win) {
                findWinJackpot.jackpot = findWinJackpot.win;
                jackpotLastWinIds.push(findWinJackpot.id);

                localStorage.setItem('jackpotLastWinIds', JSON.stringify(jackpotLastWinIds));
            }

            setWinJackpot(findWinJackpot);

            if (openPage === PAGE_NAME.intro) {
                setHidingPage(PAGE_NAME.intro);
                audio.play();
            }
        }
    }, [userId, jackpots, winJackpot]);

    useEffect(() => {
        if (isLoadedContent && isLoadedImgs && !showContent) {
            setTimeout(() => {
                setShowContent(true);
            }, 1000) // delay for show content, need time for load background
        }
    }, [isLoadedContent, isLoadedImgs]);


    const completeHidingIntroPage = () => {
        if (skipCountdown) {
            setOpenPage(PAGE_NAME.spin);
        } else {
            setOpenPage(PAGE_NAME.сountdown);
        }
    }

    const completeHidingCountdownPage = () => {
        setOpenPage(PAGE_NAME.spin);
    }

    const completeAnimationSpin = () => {
        setHidingPage(PAGE_NAME.spin);
    }

    const completeHidingSpinPage = () => {
        setOpenPage(PAGE_NAME.complete);

        setTimeout(() => { // close complete page after 8s
            setHidingPage(PAGE_NAME.complete);
        }, 12000);
    }

    const completeHidingCompletePage = () => {
        handleComplete();
        setWinJackpot(null);
        setHidingPage(null);
        setOpenPage(PAGE_NAME.intro);
    }


    if (!isLoadedContent || !isLoadedImgs) return null;

    return <JackpotSpinContext.Provider value={theme}>
        <div className={classNames('jackpot-spin-view', {
            fullScreen: openPage !== PAGE_NAME.intro,
            'jackpot-spin-view-royal': theme === 'royal'
        })}>
            {showContent && <>
                <Coinfall
                    count={openPage === PAGE_NAME.complete ? (size.mobile ? 60 : 80) : 10}
                    fallType={openPage === PAGE_NAME.complete && (size.landscape || size.mobile) ? 'up-down' : 'down'}
                    startLine={openPage === PAGE_NAME.complete && (size.landscape || size.mobile) ? 110 : undefined}
                    oneWaveDuration={openPage === PAGE_NAME.complete ? 10 : undefined}
                    hiding={hidingPage === PAGE_NAME.complete}
                />

                {openPage === PAGE_NAME.intro && <>
                    <div className={classNames('jackpot-spin-view__logo', {
                        'jackpot-spin-view__logo-fadeout': hidingPage === PAGE_NAME.intro,
                        'jackpot-spin-view__logo-royal': theme === 'royal'
                    })}
                        onTransitionEnd={completeHidingIntroPage}>
                    </div>

                    {theme === 'royal' && isPortraitDevice && <div className={classNames('jackpot-spin-view__logo-grand-royal', {
                        'jackpot-spin-view__logo-grand-royal-fadeout': hidingPage === PAGE_NAME.intro,
                        'jackpot-spin-view__logo-grand-royal-royal': theme === 'royal'
                    })} />}

                    <JackpotList
                        jackpots={jackpots}
                        hiding={hidingPage === PAGE_NAME.intro}
                        theme={theme}
                    />

                    <Spin hiding={hidingPage === PAGE_NAME.intro} theme={theme} />
                </>}
                { openPage !== PAGE_NAME.intro && <ButtonClose action={completeHidingCompletePage}/>}

                {openPage === PAGE_NAME.сountdown && <BackCounter completeCallback={completeHidingCountdownPage} />}


                {openPage === PAGE_NAME.spin &&
                    <>
                        <div className="jackpot-spin-view__logos-wrapper">
                            <div className="jackpot-spin-view__logo jackpot-spin-view__logo-royal sp1"></div>
                            <div className="jackpot-spin-view__logo-grand-royal jackpot-spin-view__logo-grand-royal-royal sp1"></div>
                        </div>
                        <Spin
                        pageName={openPage}
                        winJackpotName={winJackpot?.name}
                        completeCallback={completeAnimationSpin}
                        completeHidingSpinPage={completeHidingSpinPage}
                        hiding={hidingPage === PAGE_NAME.spin}
                        theme={theme}
                        />
                    </>
                }


                {openPage === PAGE_NAME.complete && <JackpotComplete
                    jackpot={winJackpot}
                    hiding={hidingPage === PAGE_NAME.complete}
                    completeHidingCompletePage={completeHidingCompletePage}
                    theme={theme}
                />}
            </>}
        </div>
    </JackpotSpinContext.Provider>;
}

JackpotSpin.propTypes = {
    skipCountdown: PropTypes.bool,
    handleComplete: PropTypes.func,
    startPage: PropTypes.oneOf([...Object.keys(PAGE_NAME)]),
    theme: PropTypes.oneOf(['royal', 'default']),
    allJackpots: PropTypes.array
}

JackpotSpin.defaultProps = {
    skipCountdown: true,
    handleComplete: () => { },
    startPage: PAGE_NAME.intro,
    theme: 'default',
    allJackpots: undefined
}