import React from 'react'
import { useSelector } from 'react-redux'
import { Header, LobbyContent, FooterMobile } from 'components'

import './Lobby.scss'

export const Lobby = () => {

	const size = useSelector(state => state.handling.size)
	const game = useSelector(state => state.game)

  return (
    <div className="content">
      {(!game.code || (size.mobile && game.code && !game.fullscreen) || !size.mobile) && <Header />}

      {<LobbyContent />}

      { (size.mobile && (!game.code || (size.mobile && game.code && !game.fullscreen))) && <FooterMobile />}
    </div>
  )
}