import React from 'react'
import {
	BrowserRouter,
  } from "react-router-dom"

export const Router = ({ children }) => {
	return (
		<BrowserRouter>
			{ children }
		</BrowserRouter>
	)
}