import { GET_WORDS } from "../actions/actionTypes";

const initialState = {
  server: {},
  local: {}
};

export const wordsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WORDS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
