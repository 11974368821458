import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthForm, getPopup, setPreloader } from '@bets/components/redux/actions'
import { Download, GoogleReCaptcha } from '@bets/components'
import { formatUrlVersion } from '@bets/features'

import { LoginForm, ForgotForm, SignInForm, RecoveryForm } from '@bets/components/Auth'
import { ButtonLanguages, ButtonSound, ButtonClose } from '@bets/elements'

import './Auth.scss'

export const Auth = ({ visible = false }) => {

	const words = useSelector(state => state.words)
	const casino = useSelector(state => state.config.casino)
	const settings = useSelector(state => state.settings)
	const authFormType = useSelector(state => state.handling.activeAuth)
	const preloader = useSelector(state => state.handling.preloader)
	const manualDeposit = useSelector(state => state.config.manualDeposit)
	const beforeAuthorize = useSelector(state => state.profile.beforeAuthorize)

	const dispatch = useDispatch()

	const formName = (authFormType) => { 
		switch (authFormType) {
			case 'login':
				return {
					name: words.server.login,
					link: 'signin'
				}
			case 'signin':
				return {
					name: words.server.registration,
					link: words.server.login
				}
			case 'forgot':
				return {
					name: words.server.forgot_password,
					link: words.server.login
				}
			case 'recovery':
				return {
					name: words.server.registration,
					link: words.server.login
				}                       
			default:
				return {
					name: words.server.login,
					btnText: words.server.login,
					link: ''
				}
		}
	}
	
	useEffect(() => {
		if (preloader) {
			setTimeout(function () {
				dispatch(setPreloader(false))
			}, 3000)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const renderForm = type => {
		switch (type) {
			case 'login':
				return <LoginForm info={ manualDeposit ? words.local.manualDepositInfo : false  } showSignInButton={ true } showForgotButton={ true }/>
			case 'signin': 
				return <GoogleReCaptcha><SignInForm info={  manualDeposit ? words.local.manualDepositInfo : false  }/></GoogleReCaptcha>
			case 'forgot':
				return <ForgotForm/>
			case 'recovery':
				return <RecoveryForm/>
			default:
				return <LoginForm info={ manualDeposit ? words.local.manualDepositInfo : false  }/>
		}
	}
	const backHandler = () => dispatch( getAuthForm('login') )
	
	return (
		<div className="Auth" data-visible={ visible }>
			<div className="content">
				<div className="window" data-visible={ preloader ? false : true }>

					{ beforeAuthorize && <ButtonClose action={ () => dispatch( getPopup({ active: false, name: '' }) )}/>}
					<div className="logo">
						<img src={formatUrlVersion("images/logo.png")} alt="Logo"/>
					</div>

					<div className="top">
						<div className="name">
							{ (casino && authFormType !== 'login') && 
								<div className="Button BackButton" onClick={ backHandler }>
									<img src={formatUrlVersion("images/icons/arrow-left.svg")} alt="Back" />
								</div>
							}
							<span>{ authFormType === 'login' ? formName(authFormType).name : formName(authFormType).link }</span>
						</div>
						<div className="controls">
							<ButtonSound />
							<ButtonLanguages direction={'bottom'}/>
						</div>
					</div>

					<div className="forms">
						{ renderForm(authFormType) }
					</div>
				</div>
				{ settings.download.enable && !preloader && <Download /> }
			</div>
		</div>  
	)
}
