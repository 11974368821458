import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Mousewheel, Lazy, Virtual, Scrollbar } from "swiper";
import { GameCard } from "components";

import "./CategoriesSlider.scss";

SwiperCore.use([Navigation, Mousewheel, Lazy, Virtual, Scrollbar]);

export const CategoriesSlider = ({ categories = ["top", "new"], onClick, onClickAll, visibleBack, visibleAll }) => {
  const views = useSelector((state) => state.games.views);
  const size = useSelector((state) => state.handling.size);
  const [active, setActive] = useState(
    window.localStorage.getItem("lastCategoryOnSlider")
      ? window.localStorage.getItem("lastCategoryOnSlider")
      : categories[1]
  );
  const [swiper, setSwiper] = useState(null);

  const getSlidesPerView = () => {
    if (size.width > 2400) {
      return 11;
    }
    if (size.width < 2400 && size.width > 1920) {
      return 10;
    }
    if (size.width < 1920 && size.width > 1600) {
      return 6;
    }
    if (size.width < 1600 && size.width > 1300) {
      return 8;
    }
    if (size.width < 1300 && size.width > 1200) {
      return 6;
    }
    if (size.width < 1200 && size.width > 1100) {
      return 5;
    }
    return 6;
  };
  const [slidesPerView, setSlidesPerView] = useState(getSlidesPerView());

  useEffect(() => {
    let val = getSlidesPerView();
    setSlidesPerView(val);
  }, [size]);

  let params = {
    slidesPerView: slidesPerView,
    navigation: {
      prevEl: ".slider-btns-previous",
      nextEl: ".slider-btns-next",
    },
    onInit: (swiper) => setSwiper(swiper),
    initialSlide: window.localStorage.getItem("lastProviderSlide")
      ? window.localStorage.getItem("lastProviderSlide")
      : 0,
    onSlideChange: (swiper) => window.localStorage.setItem("lastProviderSlide", swiper.realIndex),
  };

  let paramsCategory = {
    slidesPerView: categories.length <= 2 ? 2 : 3,
    spaceBetween: size.width > 1920 ? 15 : 10,
    loop: true,
    navigation: {
      prevEl: ".menu-btns-previous",
      nextEl: ".menu-btns-next",
    },
    onInit: (swiper) => setSwiper(swiper),
    initialSlide: window.localStorage.getItem("lastProviderSlide")
      ? window.localStorage.getItem("lastProviderSlide")
      : 0,
    onSlideChange: (swiper) => window.localStorage.setItem("lastProviderSlide", swiper.realIndex),
  };

  const renderSlides = (games) =>
    games.map((game) => {
      let img = game.img.replace("game_img", "game_img_4");

      return (
        <SwiperSlide key={game.id}>
          <GameCard id={game.id} name={game.name} label={game.label} img={img} demo={false} fs={game.fs} lazy={false} />
        </SwiperSlide>
      );
    });

  const handleCategoryClick = (item) => {
    window.localStorage.setItem("lastProviderSlide", 0);
    window.localStorage.setItem("lastCategoryOnSlider", item);
    swiper.slideTo(0);
    setActive(item);
  };

  const renderCategory = () =>
    categories.map((item, i) => (
      <SwiperSlide key={i}>
        <div
          className="category"
          key={`cat-${item}`}
          onClick={() => handleCategoryClick(item)}
          data-active={item === active ? true : false}
        >
          <span>{item.replace("_", " ")}</span>
        </div>
      </SwiperSlide>
    ));

  let games = views[active];
  if (games && games.length > 0) {
    games = games.filter((game) => game);
  } else {
    games = false;
  }

  const renderProvidersButton = () => {
    if (visibleBack) {
      return (
        <div className="category-providers" onClick={onClick}>
          <span>Providers</span>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderAllGamesButton = () => {
    if (visibleAll) {
      return (
        <div className="all-games" onClick={onClickAll}>
          <span>All Games</span>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="CategoriesSlider">
      <div className="menu">
        <div className="menu-btns">
          <div className="menu-btns-previous"></div>
          <div className="menu-btns-next"></div>
        </div>
        <Swiper
          {...paramsCategory}
          style={{
            maxWidth: categories.length <= 2 ? "calc(100% / 3)" : "",
          }}
        >
          {renderCategory()}
        </Swiper>
        {renderAllGamesButton()}
        {renderProvidersButton()}
      </div>
      {games && (
        <div className="slider">
          <div className="slider-container">
            <div className="slider-btns">
              <div className="slider-btns-previous"></div>
              <div className="slider-btns-next"></div>
            </div>
            <Swiper {...params}>{renderSlides(games)}</Swiper>
          </div>
        </div>
      )}
    </div>
  );
};
