import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveGameList } from '@bets/components/redux/actions'
import { Dropdown, Categories } from '@bets/components'
import { Search, ButtonLanguages } from '@bets/elements'
import './FooterMobile.scss'

export const FooterMobile = () => {

	const dispatch = useDispatch()
	const size = useSelector(state => state.handling.size)
	const words = useSelector(state => state.words)

	const types = useSelector(state => state.games.types)
	const activeProvider = useSelector(state => state.handling.activeGameList)
	const providers = useSelector(state => state.games.providers)
	const preloader = useSelector(state => state.handling.preloader)

	return (
		<footer className="footer-mobile" data-visible={ !preloader }>
			{
				size.landscape ?
					<div className="wrapper">
						<div className="dropdowns">
							<Dropdown placeholder={ words.server.categories } type='types' list={ types } activeItem={ activeProvider } onClick={ style => dispatch( setActiveGameList(style) ) }/>
							<Dropdown placeholder={ words.server.providers } type='providers' list={ providers } activeItem={ activeProvider } onClick={ provider => dispatch( setActiveGameList(provider) ) }/>
						</div>
						<div className="controls">
							<Search /> 
							<ButtonLanguages direction={'top'}/>
						</div>	
					</div>
				:
					<Categories />
			}
		</footer>
	)
}