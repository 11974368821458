import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'

import { Games } from 'components'
import { GameFrame, JackpotSpin, JackpotList } from '@bets/components'

export const LobbyContent = () => {
    const [hasWinJackpot, setHasWinJackpot] = useState(false)

    const size = useSelector(state => state.handling.size)
    const game = useSelector(state => state.game)
    const config = useSelector(state => state.config)

    const preloader = useSelector(state => state.handling.preloader)
    const jackpotsShow = useSelector(state => state.config.jackpotsShow)
    const authorized = useSelector(state => state.profile.authorized)
    const games = useSelector(state => state.games)
    const jackpots = useSelector(state => state.profile.info.jackpots)
    const userId = useSelector(state => state.profile.info.id)

    const avaibleJackpot = authorized && jackpotsShow && jackpotsShow.header;

    // useEffect(() => { //emulate local winJackpot
    //     if (!preloader) {
    //         setTimeout(() => {
    //             setHasWinJackpot(true);
    //         }, 5000);
    //     }
    // }, [preloader])

    useEffect(() => {
        if (avaibleJackpot && jackpots?.length && (size?.landscape || size?.mobile) && !preloader) {
            const findWinJackpot = jackpots?.find(j => j.user === userId);

            if (findWinJackpot) setHasWinJackpot(true)
        }
    }, [jackpots, size, avaibleJackpot, preloader, userId])

    const completeCallbackHorizontal = () => {
        setHasWinJackpot(false);
    }

    const renderVerticalJackpots = () => {
        if (avaibleJackpot && !size.landscape && !size.mobile) {
            if (config.reactJackpotWin && (config.reactJackpotWin === 'jacuts' || config.reactJackpotWin === 'jacuts_royal')) {
                return <div className="vertical-desktop-jackpot-spin" data-visible={!preloader}>
                    <JackpotSpin theme='royal' skipCountdown />
                </div>
            } else {
                return <div className="vertical-desktop-jackpots" data-visible={ !preloader }>
                <JackpotList type={'mobile-list'}/>
                </div>
            }
        }
    }

    return (
        <>
            { renderVerticalJackpots() }
            {/*(!game.code && !size.mobile && !size.landscape) && <ProvidersBar />*/}
            {game.code && <GameFrame gameCode={game.code} />}
            { hasWinJackpot && (size.landscape || size.mobile) && avaibleJackpot && (config.reactJackpotWin && (config.reactJackpotWin === 'jacuts' || config.reactJackpotWin === 'jacuts_royal')) &&
                <JackpotSpin theme='royal' skipCountdown handleComplete={completeCallbackHorizontal} startPage={'spin'} />
            }
            {(games.loaded) && <Games visible={!game.code}/>}
        </>
    )
}