import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Lobby, Popup } from "components";
import { Auth } from "@bets/components/Auth";
import { Preloader, Profile } from "@bets/components";
import { A2hs, ChatButton } from "@bets/helpers";

import "./Enter.scss";

export const Enter = () => {
    
  const profile = useSelector(state => state.profile)
  const handling = useSelector(state => state.handling)
  const config = useSelector(state => state.config)
  const settings = useSelector(state => state.settings)


  useEffect(() => {
    const body = window.document.getElementsByTagName("body");
    if (handling.size.mobile) {
      handling.activePopup.active ? (body[0].style.overflow = "hidden") : (body[0].style.overflow = "visible");
    }
  }, [handling.activePopup, handling.size.mobile]);

  const renderAlerts = () => (
    <div className="alerts" data-authorized={ profile.authorized }>
      { settings.jivosite && <ChatButton onClick={ () => window.jivo_api.open() }/> }
      { (config.PWA && !localStorage.pwa) && <A2hs /> }
    </div>
  );

  const falsePreloader = (handling.preloader = false);

  return (
    <div className="view">
      {handling.size.mobile && renderAlerts()}
      <Preloader visible={falsePreloader} />
      <Popup />
      {profile.beforeAuthorize || profile.authorized ? <Lobby /> : <Auth />}
      {profile.authorized && <Profile visible={handling.activePopup.name === "account"} />}
    </div>
  );
};
