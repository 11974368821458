import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAuthorization, getAuthForm, setPreloader, getPopup, setAudioEvent } from '@bets/components/redux/actions'
import { Input } from '@bets/components'
import { Button, InputPassword, PhoneInput } from '@bets/elements'
import './LoginForm.scss'

export const LoginForm = ({ 
        visible = true, 
        info = false,
        labelsForInput = false,
        showForgotButton = false, 
        showSignInButton = false 
    }) => {

    const dispatch = useDispatch()
    const words = useSelector(state => state.words)
    const config = useSelector(state => state.config)
    const settings = useSelector(state => state.settings)
    
    const [formData, setFormData] = useState({})
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState(false)
    const [error, setError] = useState(false)

    window.core.functions.onQueryError = (data, response) => {
        data.cmd === "terminalLogin" && setError(response.error)   
    }

    const login = event => {
        event.preventDefault()
        setLoading(true)

        let login = type === 'phone' ? formData.dialCode + formData.phone : formData.email

        setTimeout(function () {
            setLoading(false)
            window.core.userLogin(login, formData.password, 0, 0)
        }, 300)
    }

    const numberChange = (isvalid, value, event) => {
        setError(false)
        setFormData({ ...formData, dialCode: event.dialCode, phone: value })
        let type = value && value.length > 0 && value !== '' ? 'phone' : false
        setType(type)
    }

    const emailChange = event => {
        let value = event.target.value
        getFormVal('email', value)
        let type = value && value.length > 0 && value !== '' ? 'email' : false
        setType(type)
    }
    
    const codeChange = (isvalid, value) => {
        getFormVal('dialCode', value.dialCode)
    }

    const handleChangePassword = event => {
        getFormVal('password', event.target.value)
    }

    const getFormVal = (type, value) => {
        setError(false)
        setFormData({ ...formData, [type]: value })
    }

    const getValidate = () => {
        if (!settings.validate) return false
        let valid = !error && (formData.password && formData.password !== '') && ((formData.phone && formData.phone !== '') || (formData.email && formData.email !== '')) ? false : true
        return valid
    }

    let or = config.casino && settings.registrationData.phone && settings.registrationData.email

    return (
        <form className="Login form" data-visible={ visible } onSubmit={ event => login(event) }>
        
            { config.casino && settings.registrationData.phone && 
                <PhoneInput 
                  onChange={ numberChange } 
                  onChangeCountry={ codeChange } 
                  disabled={ type === 'email' } />
            }

            { or && <div className="or"> <span>{ words.server.or }</span></div> }

            { settings.registrationData.email && 
                <Input 
                    type="mail"
                    disabled={ type === 'phone' }
                    placeholder={ config.casino ? words.server.email + " / " + words.server.log_in : words.server.log_in } 
                    onChange={ emailChange }
                    label={ labelsForInput } />
            }

            <InputPassword onChange={ handleChangePassword } label={ labelsForInput }/>

            <Button 
                className='submit-button'
                loading={ loading } 
                disabled={ getValidate() } >
                    { words.server.login }
            </Button>
            
            { error && <div className="error">{ error }</div> }

            { (config.casino && showForgotButton) && <Button className="restore-button" onClick={ () => dispatch( getAuthForm('forgot') )}>{ words.server.forgot_password }</Button>}
            { (config.casino && showSignInButton) && <Button className='signin-button' onClick={ () => dispatch( getAuthForm('signin') )}>{ words.server.registration }</Button> }

            { (settings.info && info) && <div className='info'><p dangerouslySetInnerHTML={{ __html: info }}></p></div> }
        </form>
    )
}