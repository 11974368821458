import { GET_AUTHORIZATION, SET_BEFORE_AUTHORIZE, GET_PROFILE_INFO } from "../actions/actionTypes"

const initialState = {
  authorized: window.config.system.authorized,
  beforeAuthorize: false,
  detect: window.config.system.authorized ? true : false,
  info: {}
}

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AUTHORIZATION:
      return {
        ...state,
        authorized: action.payload,
      }
    case SET_BEFORE_AUTHORIZE:
      return {
        ...state,
        beforeAuthorize: action.payload,
        detect: true
    }
    case GET_PROFILE_INFO:
      return {
        ...state,
        info: action.payload,
      }
    default:
      return state;
  }
}