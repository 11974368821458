import React from 'react'
import { useSelector } from 'react-redux'

import './News.scss'

export const News = ({ visible }) => {

	const news = useSelector(state => state.profile.info.news)

	const initialDuration = 30
	let count = news ? news.length : 0

	if (count > 0) {
		return (
			<section className="news" data-visible={ visible }>
				<div className="wrapper" >
					<div className="list" style={{ animationDuration: `${initialDuration * count}s` }}>
						{ news.map( (text, index) => <span className="item" key={ 'key_' + index }>{ text }</span> )}
					</div>
				</div>
			</section>
		)
	} else return <></>
}