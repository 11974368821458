import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './BackCounter.scss';

import audioSrc from './counter-sound-effect.mp3';
const audio = new Audio(audioSrc);
audio.type = "audio/mp3";
audio.volume = 0.5;

export const BackCounter = ({ completeCallback }) => {
    const [counter, setCounter] = useState(5);

    useEffect(() => {
        //audio.play();

        return () => {
           // audio.pause();
           // audio.currentTime = 0;
        };
    }, [])

    const animationIterationCallback = () => {
        setCounter(counter - 1);
    }

    return <div className='back-counter-wrap'
        onAnimationEnd={completeCallback}
        onAnimationIteration={animationIterationCallback}>
        {counter}
    </div>
};

BackCounter.propTypes = {
    completeCallback: PropTypes.func,
}

BackCounter.defaultProps = {
    completeCallback: () => { }
}