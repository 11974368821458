import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import './TransactionHistory.scss'

function dateToString(datetime, delimiter) {
    if (delimiter === undefined) {
        delimiter = '-';
    }
    var month = datetime.getMonth() + 1;
    if (month < 10) {
        month = '0' + month;
    }
    var date = datetime.getDate();
    if (date < 10) {
        date = '0' + date;
    }
    return datetime.getFullYear() + delimiter + month + delimiter + date;
}

export const TransactionHistory = () => {

    const words = useSelector(state => state.words)

    const [transactionsList, setTransactionsList] = useState([])
    const [start, setStart] = useState({ date: dateToString(new Date()), time: '00:00' })
    const [end, setEnd] = useState({ date: dateToString(new Date()), time: '23:59' })
    const [error, setError] = useState('')

    const showTransactions = () => {
    
        let response = window.core.query({
            'cmd': 'casinoCashHistory',
            'start': start.date + " " + start.time,
            'end': end.date + " " + end.time,
            'operation': 'in'
        });
        
        if (response.status == 'success') {
            if (response.content.length > 0) {
                setTransactionsList(response.content)
                setError('')
            } else {
                setTransactionsList([])
                setError('Transactions not found')
            }
        } else if (response.status == 'fail') {
            setError(response.error)
            
        }
        console.log(response)
    }

    const renderList = items => {
        let list = items.map( (item) => (
            <div key={ item.id } className="row transaction">
                <span className="id"> { item.id } </span>
                <span className="amount"> { item.cash }</span>
                <span className="time"> { item.datetime } </span>
            </div>
        ))

        return (
            <div className="list">
                <div className="row head">
                    <span className="id">{ words.server.id }</span>
                    <span className="amount">{ words.server.amount}</span>
                    <span className="time">{ words.server.time }</span>
                </div>
                { list }
            </div>
        )
    }

    return (
        <div className="TransactionHistory">
            <div className="wrapper">
                <div className="settings">
                    <div className="start">
                        <p className="title">{ words.server.start }</p>
                        <div className="inputs">
                            <div className="input-date">
                                <input
                                className="date"
                                type="date"
                                value={ start.date }
                                onChange={ e => setStart({ ...start, date: e.target.value }) }
                                />
                                <div className="arrow"></div>
                            </div>

                            <div className="input-date">
                                <input
                                className="time"
                                type="time"
                                value={ start.time }
                                onChange={ e => setStart({ ...start, time: e.target.value }) }
                                />
                                <div className="arrow"></div>
                            </div>
                        </div>
                    
                    </div>
                    
                    <div className="end">
                        <p className="title">{ words.server.the_end }</p>
                        <div className="inputs">
                            <div className="input-date">
                                <input
                                className="date"
                                type="date"
                                value={ end.date }
                                onChange={ e => setEnd({ ...end, date: e.target.value }) }
                                />
                                <div className="arrow"></div>
                            </div>

                            <div className="input-date">
                                <input
                                className="time"
                                type="time"
                                value={ end.time }
                                onChange={ e => setEnd({ ...end, time: e.target.value }) }
                                />
                                <div className="arrow"></div>
                            </div>
                        </div>
                    
                    </div>
                </div>

                <div className="show" onClick={ () => showTransactions() }>{ words.server.confirm }</div>

                    { transactionsList.length > 0 && renderList(transactionsList) }
                    { error != '' && <div className="error">{ error }</div>}
            </div>
        </div>
    )
}