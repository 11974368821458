import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setPreloader } from '@bets/components/redux/actions';
import { useMediaQuery } from 'react-responsive'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Mousewheel, Lazy, Virtual, Scrollbar } from 'swiper'
import ReactPaginate from 'react-paginate';
import { GameCard } from 'components';
import { PaginateBtn } from './PaginateBtn/PaginateBtn';
import { isPortrait } from "@bets/consts";
import uuid from 'react-uuid';

import { PageGamesBlock } from './PageGamesBlock/PageGamesBlock';

import './PaginationSlider.scss';
import classNames from 'classnames';

SwiperCore.use([Navigation, Mousewheel, Lazy, Virtual, Scrollbar]);

export const PaginationSlider = ({ games }) => {
    const dispatch = useDispatch();

    const size = useSelector(state => state.handling.size)
    const settings = useSelector(state => state.settings);
    const loaded = useSelector(state => state.games.loaded);


    const [renderGames, setRenderGames] = useState([]);

    const isPortraitScreen = useMediaQuery(isPortrait);
    const [pageCount, setPageCount] = useState(0);
    const [swiper, setSwiper] = useState();
    const [forcePageNum, setForcePageNum] = useState(0);
    const [gamePerRow, setGamePerRow] = useState(6);
    const [rows, setRows] = useState(2);
    const [remember, setRemember] = useState(false);

    const createGames = (games) => {
        //console.log(games);
        const pageGames = [];
        const gameBlock = [];

        for (const [index, game] of games.entries()) {
            const img = game.img.replace('game_img', 'game_img_4');

            gameBlock.push(<GameCard
                key={game.id}
                id={game.id}
                name={game.name}
                label={game.label}
                img={img}
                demo={settings.demo}
                fs={game.fs}
                lazy={false}
            />)

            if (gameBlock.length === gamePerRow * rows) {
                pageGames.push(
                  <SwiperSlide
                    virtualIndex={uuid()}
                    key={uuid()}
                  >
                    <PageGamesBlock key={uuid()}>{gameBlock.slice()}</PageGamesBlock>
                </SwiperSlide>)
                gameBlock.splice(0, gameBlock.length);
            }

            if (index === games.length - 1) {
                if (gameBlock.length % (rows * gamePerRow) > 0) {
                    for (let i = 10, countEmpty = (rows * gamePerRow) - gameBlock.length % (rows * gamePerRow); i < countEmpty; i++) {
                        gameBlock.push(<GameCard key={uuid()} hidden />)
                    }

                    pageGames.push(<SwiperSlide key={uuid()}>
                        <PageGamesBlock key={uuid()}>{gameBlock}</PageGamesBlock>
                    </SwiperSlide>);
                }
            }
        }

        return pageGames;
    }

    const calcCountOfSlide = () => {
        const heightSize = window.innerHeight * 0.24;
        const widthSize = window.innerWidth * 0.96;

        if (size.width > 1100) {
          //  return 5
        } else {
          //  return 4
        }
        const preCalc = Math.ceil(widthSize / heightSize);
        const actualCalc = Math.floor((widthSize - window.innerWidth * preCalc / 100) / heightSize);

        return actualCalc;
    }

    useEffect(() => {
        setRows(isPortraitScreen ? 6 : 2);
    }, [isPortraitScreen])

    useEffect(() => {
        let sliderPage = localStorage.getItem('sliderPage')

        if (swiper && !remember && renderGames && sliderPage && sliderPage !== 0) {
            setForcePageNum(sliderPage);
            setTimeout(() => {
                swiper.slideTo(sliderPage, 0)
                
                setRemember(true)
            }, 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [swiper])

    useEffect(() => {
        if (!size.landscape) {
            setGamePerRow(5)
            if(window.innerHeight < 1200) {
                setRows(2)
            } else {
                setRows(4)
            }
        }
        
        if (size.landscape) {
            setRows(2)
            setGamePerRow(isPortraitScreen ? 6 : calcCountOfSlide());
        }
    }, [isPortraitScreen, size])
    
    const [pageRangeDisplayed, setPageRangeDisplayed] = useState(7)    
    
    useEffect(() => {
        if (size.width >= 1301 && size.width <= 1850 && size.landscape === true) {
            setPageRangeDisplayed(3)
        } else if (size.width <= 1300 && size.landscape === true) {
            setPageRangeDisplayed(2)
        } else if (size.width > 1850 && size.landscape === true) {
            setPageRangeDisplayed(7)
        }
    }, [size])



    useEffect(() => {
        if (games) {
            if (games.length > 0) {
                let items = createGames(games);
                setRenderGames(items);
                setPageCount(items.length);
                setTimeout(() => dispatch(setPreloader(false)), 300);
            } else {
                setRenderGames([]);
                setPageCount(0);
                setTimeout(() => dispatch(setPreloader(false)), 300);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [games, rows, gamePerRow, loaded, swiper])

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        swiper.slideTo(event.selected)
        setForcePageNum(event.selected);
    };

    const handlePreviousPageClick = () => {
        const newPage = forcePageNum - 1 >= 0 ? forcePageNum - 1 : pageCount - 1;

        swiper.slideTo(newPage)
        setForcePageNum(newPage);
    }

    const handleNextPageClick = () => {
        const newPage = forcePageNum + 1 < pageCount ? forcePageNum + 1 : 0;
        swiper.slideTo(newPage)
        setForcePageNum(newPage);
    }

    const handleFirstPageClick = () => {
        const newPage = forcePageNum - 10 > 0 ? forcePageNum - 10 : 0;

        swiper.slideTo(newPage)
        setForcePageNum(newPage);
    }

    const handleLastPageClick = () => {
        const newPage = forcePageNum + 10 < pageCount ? forcePageNum + 10 : pageCount - 1;

        swiper.slideTo(newPage)
        setForcePageNum(newPage);
    }


    return <div className='games-paginate-block'>
        <div className={`games-paginate-block__content content-grid content-grid_cell${gamePerRow * rows}`}>
            <div
                className={classNames('games-paginate-block__content-swipe',
                    'games-paginate-block__content-swipe-previous')}
                onClick={handlePreviousPageClick}></div>
  
            <Swiper
                slidesPerView={1}
                watchSlidesVisibility={ true }
                preloadImages={ true }
                lazy={{
                    loadPrevNext: true,
                    loadPrevNextAmount: 4
                }}
                virtual={{
                    addSlidesAfter: 5,
                    addSlidesBefore: 3
                }}
                onSwiper={(swiper) => setSwiper(swiper)}
                onSlideChange={ swiper => { 
                    setForcePageNum(swiper.snapIndex); 
                    (swiper.snapIndex !== null && swiper.snapIndex > 0) ? window.localStorage.setItem('sliderPage', swiper.snapIndex) : window.localStorage.setItem('sliderPage', 0); 
                }
                }
            >
                {renderGames}
            </Swiper>

            {/*<Swiper {...slideParams}>
                {renderGames}
            </Swiper>*/}

            <div
                className={classNames('games-paginate-block__content-swipe',
                    'games-paginate-block__content-swipe-next')}
                onClick={handleNextPageClick}></div>
        </div>

        <div className='games-paginate-block__control'>
            <PaginateBtn title='<<' onClick={handleFirstPageClick} />

            <ReactPaginate
                nextLabel='>'
                forcePage={forcePageNum}
                onPageChange={handlePageClick}
                pageRangeDisplayed={pageRangeDisplayed}
                marginPagesDisplayed={3}
                pageCount={pageCount}
                previousLabel='<'
                pageClassName="games-paginate-block__control-item"
                previousClassName="games-paginate-block__control-item games-paginate-block__control-item-arrow"
                nextClassName="games-paginate-block__control-item games-paginate-block__control-item-arrow"
                activeClassName="games-paginate-block__control-item-active"
                className='games-paginate-block__control-pagination-block'
            />

            <PaginateBtn title='>>' onClick={handleLastPageClick} />

            <div className="games-paginate-block__control-btns">
                {/*<ButtonSound />
                <ButtonFullscreen />
                <ButtonLanguages theme="royal" direction={'top'} />*/}
            </div>
        </div>
    </div>
}