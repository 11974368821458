import rectangleSmallEvenXLG from './assets/blue/rectangle_small_even.png';
import rectangleSmallOddXLG from './assets/blue/rectangle_small_odd.png';
import rectangleBigEvenXLG from './assets/blue/rectangle_big_even.png';
import rectangleBigOddXLG from './assets/blue/rectangle_big_odd.png';

import rectangleSmallEvenLG from './assets/blue/lg/rectangle_small_even.lg.png';
import rectangleSmallOddLG from './assets/blue/lg/rectangle_small_odd.lg.png';
import rectangleBigEvenLG from './assets/blue/lg/rectangle_big_even.lg.png';
import rectangleBigOddLG from './assets/blue/lg/rectangle_big_odd.lg.png';

import rectangleSmallEvenMD from './assets/blue/md/rectangle_small_even.md.png';
import rectangleSmallOddMD from './assets/blue/md/rectangle_small_odd.md.png';
import rectangleBigEvenMD from './assets/blue/md/rectangle_big_even.md.png';
import rectangleBigOddMD from './assets/blue/md/rectangle_big_odd.md.png';

import rectangleSmallEvenSM from './assets/blue/sm/rectangle_small_even.sm.png';
import rectangleSmallOddSM from './assets/blue/sm/rectangle_small_odd.sm.png';
import rectangleBigEvenSM from './assets/blue/sm/rectangle_big_even.sm.png';
import rectangleBigOddSM from './assets/blue/sm/rectangle_big_odd.sm.png';

import spinSM from './assets/blue/sm/full_spin.sm.png';
import spinMD from './assets/blue/md/full_spin.md.png';
import spinLG from './assets/blue/lg/full_spin.lg.png';
import spinXLG from './assets/blue/full_spin.png';

import bright from './assets/bright.png';
import spin from './assets/blue/spin-selector.png';

import bronzeXLG from './assets/blue/bronze.png';
import diamondXLG from './assets/blue/diamond.png';
import goldXLG from './assets/blue/gold.png';
import ironXLG from './assets/blue/iron.png';
import platinumXLG from './assets/blue/platinum.png';
import silverXLG from './assets/blue/silver.png';

import bronzeLG from './assets/blue/lg/bronze.lg.png';
import diamondLG from './assets/blue/lg/diamond.lg.png';
import goldLG from './assets/blue/lg/gold.lg.png';
import ironLG from './assets/blue/lg/iron.lg.png';
import platinumLG from './assets/blue/lg/platinum.lg.png';
import silverLG from './assets/blue/lg/silver.lg.png';

import bronzeMD from './assets/blue/md/bronze.md.png';
import diamondMD from './assets/blue/md/diamond.md.png';
import goldMD from './assets/blue/md/gold.md.png';
import ironMD from './assets/blue/md/iron.md.png';
import platinumMD from './assets/blue/md/platinum.md.png';
import silverMD from './assets/blue/md/silver.md.png';

import bronzeSM from './assets/blue/sm/bronze.sm.png';
import diamondSM from './assets/blue/sm/diamond.sm.png';
import goldSM from './assets/blue/sm/gold.sm.png';
import ironSM from './assets/blue/sm/iron.sm.png';
import platinumSM from './assets/blue/sm/platinum.sm.png';
import silverSM from './assets/blue/sm/silver.sm.png';

import jackpotLogoXLG from './assets/blue/jackpot_logo.png';
import jackpotLogoLG from './assets/blue/lg/jackpot_logo.lg.png';
import jackpotLogoMD from './assets/blue/md/jackpot_logo.md.png';
import jackpotLogoSM from './assets/blue/sm/jackpot_logo.sm.png';

import jackpotBg from './assets/blue/jackpot_bg.png';

export const commonImgList = [
    bright,
    jackpotBg,
    spin,
    platinumXLG,
    silverXLG,
    bronzeXLG,
    diamondXLG,
    goldXLG,
    ironXLG,
];

export const smImgList = [
    spinSM,
    platinumSM,
    silverSM,
    bronzeSM,
    diamondSM,
    goldSM,
    ironSM,
    jackpotLogoSM,
    rectangleSmallEvenSM,
    rectangleSmallOddSM,
    rectangleBigEvenSM,
    rectangleBigOddSM
];

export const mdImgList = [
    spinMD,
    platinumMD,
    silverMD,
    bronzeMD,
    diamondMD,
    goldMD,
    ironMD,
    jackpotLogoMD,
    rectangleSmallEvenMD,
    rectangleSmallOddMD,
    rectangleBigEvenMD,
    rectangleBigOddMD
];

export const lgImgList = [
    spinLG,
    platinumLG,
    silverLG,
    bronzeLG,
    diamondLG,
    goldLG,
    ironLG,
    jackpotLogoLG,
    rectangleSmallEvenLG,
    rectangleSmallOddLG,
    rectangleBigEvenLG,
    rectangleBigOddLG,
];

export const xlgImgList = [
    spinXLG,
    jackpotLogoXLG,
    rectangleSmallEvenXLG,
    rectangleSmallOddXLG,
    rectangleBigEvenXLG,
    rectangleBigOddXLG
];
