import React from 'react'
import { useSelector } from 'react-redux'
import {
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3"


export const GoogleReCaptcha= ({children}) => {

  const config = useSelector(state => state.config)
  
  return (
    <GoogleReCaptchaProvider reCaptchaKey={ config.captchaKey }>
      { children }   
    </GoogleReCaptchaProvider>   
  )
}