import React from 'react'
import { useSelector } from 'react-redux'
import { ButtonClose } from '@bets/elements'

import './GameFrame.scss'

export const GameFrame = ({ gameCode }) => {

	const size = useSelector(state => state.handling.size)
  const gameFrame = useSelector(state => state.game)
  const preloader = useSelector(state => state.handling.preloader)

	return (
		<div className="game-frame" data-visible={ !preloader ?  true : false}>
      { (!size.landscape && gameFrame.fullscreen) && <ButtonClose action={ () => { window.core.closeGame() } } /> }
      <div className="inner-game-frame" dangerouslySetInnerHTML={{ __html: gameCode }}></div>
    </div>
	)
}