import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import './GamesHistory.scss'

function dateToString(datetime, delimiter) {
  if (delimiter === undefined) {
      delimiter = '-';
  }
  var month = datetime.getMonth() + 1;
  if (month < 10) {
      month = '0' + month;
  }
  var date = datetime.getDate();
  if (date < 10) {
      date = '0' + date;
  }
  return datetime.getFullYear() + delimiter + month + delimiter + date;
}

const initialDates = {
  start: {
    date: dateToString(new Date()),
    time: '00:00'
  },
  end: {
    date: dateToString(new Date()),
    time: '23:59'
  }
}

export const GamesHistory = () => {

  const words = useSelector(state => state.words)
  const [dates, setDates] = useState(initialDates)
  const [gameHistory, setGameHistory] = useState(null)
  const [gameHistoryText, setGameHistoryText] = useState("")

  const showGamesHistory = () => {
    let response = window.core.query({
      cmd: "casinoGamesHistory",
      start: dates.start.date + " " + dates.start.time + ':00',
      end: dates.end.date + " " + dates.end.time + ':59'
    })

    if (response.status === "success") {
      if (response.content && response.content.length > 0) {
        setGameHistory(response.content)
        setGameHistoryText("")
      } else {
        setGameHistory(null)
        setGameHistoryText("No games found for this time period.");
      }
    }
  }

  const renderGamesHistory = gameHistory => (
    gameHistory.map(({ id, game_name, datetime_in, datetime_out }) => (
        <div className="row" key={`${id} + ${datetime_out}`}>
          <div className="gameId">{id}</div>
          <div className="gameName">{game_name}</div>
          <div className="start">
            <div className="date">{datetime_in}</div>
          </div>
          <div className="end">
            <div className="date">{datetime_out}</div>
          </div>
        </div>
      )
    )
  )

  return (
    <div className="GamesHistory">
      <form className="settings">
        <div className="block">
          <label className="title">{ words.server.start }</label>

          <div className="input-date">
            <input
              className="date"
              type="date"
              value={dates.start.date}
              id="date_start"
              onChange={(e) => setDates({ ...dates, start: {...dates.start, date: e.target.value}})}
            />
          </div>

          <div className="input-date">
            <input
              className="time"
              type="time"
              id="time_start"
              value={dates.start.time}
              onChange={(e) => setDates({ ...dates, start: {...dates.start, time: e.target.value}})}
            />
          </div>

        </div>
        <div className="block">
          <label className="title">{ words.server.the_end }</label>

          <div className="input-date">
            <input
              className="date"
              type="date"
              id="date_end"
              value={dates.end.date}
              onChange={(e) => setDates({ ...dates, end: {...dates.end, date: e.target.value}})}
            />
          </div>

          <div className="input-date">
            <input
              className="time"
              type="time"
              id="time_end"
              value={dates.end.time}
              onChange={(e) => setDates({ ...dates, end: {...dates.end, time: e.target.value}})}
            />
          </div>

        </div>
        <div className="show-history" type="submit" onClick={showGamesHistory}>
          { words.server.send_link}
        </div>
      </form>
      {gameHistory ? <div className="list">
        <div className="title">{words.server.games_history}</div>
        <div className="top">
          <div className="gameId">{words.server.id}</div>
          <div className="gameName">{words.server.fio}</div>
          <div className="start">{words.server.start}</div>
          <div className="end">{words.server.the_end}</div>
        </div>
          { renderGamesHistory(gameHistory) }
      </div> : <div className="empty">{gameHistoryText}</div>}
    </div>
  )
}