import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames';
import { Download, GamesHistory } from '@bets/components'
import { ButtonLanguages, ButtonSound, ButtonLogout, ButtonClose } from '@bets/elements'
import { getPopup } from '@bets/components/redux/actions'
import { Balance } from './balance'
import { ChangePassword } from './password-change'
import { QrCode } from './qr-code'
import { TransactionHistory } from '@bets/components/Payments/TransactionHistory'
import { Deposit } from '@bets/components/Payments/Deposit'
import { Withdraw } from '@bets/components/Payments/Withdraw'

import './Profile.scss'

export const Profile = ({ visible }) => {

    const dispatch = useDispatch()
    const profileSections = useSelector(state => state.settings.profileSections)
    const words = useSelector(state => state.words)
    const size = useSelector(state => state.handling.size)
    const [activeTab, setActiveTab] = useState('balance')

    const changeTab = type => setActiveTab(type)


    const renderTabs = tab => {
        switch (tab) {
            case 'balance':
                return <Balance/>
            case 'change_password':
                return <ChangePassword />
            case 'deposit':
                return <Deposit />
            case 'withdraw':
                return <Withdraw />
            case 'transaction_history':
                return <TransactionHistory />
            case 'games_history':
                return <GamesHistory />
            case 'qr_code':
                return <QrCode />
            default:
                return <Balance />
        }
    }

    const renderMenu = (sections, active) => (
        sections.map(section => {
            let title = words.server[section]
            return <div className="link" key={section} data-active={active === section} onClick={() => changeTab(section)}>
                <div className='ico'>
                    <img src={'images/profile/' + section + '.svg'} alt={title} />
                </div>
                <span>{title}</span>
            </div>
        })
    )

    return (
        <div className="profile" data-visible={visible}>

            <div className="profile__header">
                <div className="title">{words.server.my_account}</div>
                <div className="controls">
                    <ButtonSound />
                    <ButtonLanguages direction={'bottom'} />
                    <ButtonLogout />
                    <ButtonClose action={() => dispatch(getPopup({ active: false, name: '' }))} />
                </div>
            </div>

            <div className="profile__wrapper">

                <div className="menu">
                    {renderMenu(profileSections, activeTab)}
                    {!size.mobile && <Download type={'button'} />}
                </div>
                {
                    visible && <div className="tabs">
                        {renderTabs(activeTab)}
                    </div>
                }

            </div>

            <div className="profile__footer">
                {size.mobile && <Download type={'button'} title={words.server.download_app} />}
                {words.server.copyright_all_rights}
            </div>

        </div>
    )
}
