import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./ProvidersSlider.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Mousewheel, Navigation, Lazy, Scrollbar } from "swiper";
import { number } from "prop-types";
import { useMediaQuery } from "react-responsive";
import { isPortrait } from "@bets/consts";

SwiperCore.use([Mousewheel, Navigation, Lazy, Scrollbar]);

export const ProvidersSlider = ({ providerList, onClickProvider, visible, setInitialSlider }) => {
  const size = useSelector((state) => state.handling.size);

  // eslint-disable-next-line
  const [swiper, setSwiper] = useState(false);

  const getSlidesPerView = () => {
    if (size.width >= 768 && size.width < 992) {
      return 7;
    }
    if (size.width >= 992 && size.width <= 1024) {
      if (size.height < 768) return 6;
      return 4;
    }
    if (size.width >= 1024 && size.width < 1240) {
      if (size.height > 1400) {
        return 7;
      }
      if (size.height >= 1100) {
        return 7;
      }
      if (size.height > 992 && size.height <= 1024) {
        return 7;
      }
      if (size.height <= 992 && size.height > 768) {
        return 4;
      }
      return 5;
    }
    if (size.width >= 1240 && size.width < 1280) {
      if (size.height <= 1096 && size.height >= 992) {
        return 9;
      }
      if (size.height <= 992 && size.height > 768) {
        return 10;
      }
      if (size.height <= 768 && size.height > 520) {
        return 8;
      }
      if (size.height <= 520 && size.height > 320) {
        return 13;
      }
      return 8;
    }
    if (size.width >= 1280 && size.width < 1360) {
      if (size.height <= 1080 && size.height > 1000) {
        return 7;
      }
      if (size.height <= 1000 && size.height >= 992) {
        return 9;
      }
      if (size.height <= 992 && size.height > 950) {
        return 4;
      }
      if (size.height <= 950 && size.height > 880) {
        return 5;
      }
      if (size.height <= 880 && size.height > 740) {
        return 4;
      }

      if (size.height <= 880 && size.height > 790) {
        return 5;
      }
      if (size.height <= 790 && size.height > 768) {
        return 8;
      }
      if (size.height <= 768 && size.height > 700) {
        return 6;
      }
      if (size.height <= 700 && size.height > 650) {
        return 7;
      }
      return 8;
    }
    if (size.width >= 1360 && size.width < 1380) {
      if (size.height <= 668) {
        return 9;
      }
      if (size.height > 770) {
        return 9;
      }
      if (size.height < 770 ) {
        return 7
      }
      return 9;
    }
    if (size.width >= 1380 && size.width < 1420) {
      if (size.height > 1040) {
        return 4;
      }
      if (size.height > 900) {
        return 5;
      }
    }
    if (size.width >= 1420 && size.width < 1420) {
      if (size.height > 1200) {
        return 9;
      }
      if (size.height < 1200) {
        return 9;
      }
      if (size.height <= 1150 && size.height >= 1024) {
        return 9;
      }
      return 9;
    }
    if (size.width > 1420 && size.width < 1480) {
      if (size.height > 1200) {
        return 7;
      }
      if (size.height < 1200 && size.height > 992) {
        return 7;
      }
      if (size.height < 992 && size.height > 768) {
        return 6;
      }
      if (size.height < 768 && size.height > 520) {
        return 7;
      }
    }
    if (size.width > 1480 && size.width < 1880) {
      if (size.height > 1300) {
        return 8;
      }
      if (size.height > 1200) {
        return 5;
      }
      if (size.height <= 1200 && size.height > 1080) {
        return 4;
      }
      if (size.height <= 1080 && size.height > 992) {
        return 5;
      }
      if (size.height < 992 && size.height > 768) {
        return 7;
      }
      if (size.height < 768 && size.height > 520) {
        return 9;
      }
    }
    if (size.width >= 1880 && size.width < 2000) {
      if (size.height > 1400) {
        return 4;
      }
      if (size.height > 1096) {
        return 5;
      }
      if (size.height <= 1096 && size.height > 992) {
        return 7;
      }
      if (size.height <= 992 && size.height > 768) {
        return 9;
      }
      return 10;
    }
    if (size.width >= 2000 && size.width < 2200) {
      if (size.height > 1580) {
        return 5;
      }
      if (size.height > 1400 && size.height < 1580) {
        return 4;
      }
      if (size.height > 1300 && size.height < 1400) {
        return 5;
      }

      if (size.height > 1000 && size.height < 1100) {
        return 9;
      }
    }
    if (size.width > 2200 && size.width < 3200) {
      if (size.height < 1180) return 13;
      if (size.height < 1300 && size.height > 1180) return 7;
      if (size.height > 1300 && size.height < 1400) return 9;
      if (size.height > 1400 && size.height < 1600) return 6;
      if (size.height >= 1600) return 5;
      return 7;
    }
    if (size.width > 3200 && size.width < 3500) {
      return 11;
    }
    if (size.width > 3500) {
      if (size.height > 1800) return 10;
      if (size.height > 2500) return 7;

      return 7;
    }
    return 7;

    // 1600 1199
  };

  const getSlidesPerColumn = () => {
    if (size.width >= 768 && size.width < 1024) {
      if (size.height <= 992 && size.height > 768) {
        return 2;
      }
      return 1;
    }
    if (size.width >= 1024 && size.width < 1240) {
      if (size.height > 1440) {
        return 2;
      }
      if (size.height >= 1240) {
        return 1;
      }
      if (size.height <= 1240 && size.height > 1200) {
        return 1;
      }
      if (size.height > 1096 && size.height <= 1200) {
        return 1;
      }
      if (size.height <= 1200 && size.height >= 1096) {
        return 2;
      }
      if (size.height > 992 && size.height <= 1024) {
        return 2;
      }
      if (size.height <= 992 && size.height > 768) {
        return 2;
      }
      return 1;
    }
    if (size.width >= 1240 && size.width < 1260) {
      if (size.height < 1100 && size.height >= 992) {
        return 1;
      }
      return 1;
    }

    if (size.width >= 1260 && size.width < 1440) {
      if (size.height < 1100 && size.height >= 992) {
        return 2;
      }
      return 1;
    }
    if (size.width >= 1440 && size.width < 1920) {
      if (size.height > 1300) {
        return 2;
      }
      if (size.height > 1400) {
        return 1;
      }
      if (size.height <= 1200) {
        return 1;
      }
      return 1;
    }
    if (size.width >= 1920 && size.width < 3200) {
      if (size.height < 1600 && size.height > 1100) {
        return 1;
      }
      if (size.height > 1600) {
        return 2;
      }

      return 1;
    }
    if (size.width > 3200 && size.width < 3500) {
      if (size.height < 1440 && size.height > 1200) return 1;
      return 2;
    }
    return 1;
  };

  const [slidesPerColumn, setSlidesPerColumn] = useState(getSlidesPerColumn());
  const [slidesPerView, setSlidesPerView] = useState(getSlidesPerView());

  useEffect(() => {
    if (swiper) {
      swiper.lazy.load();
    }
  }, [swiper, size]);

  useEffect(() => {
    let val = getSlidesPerView();
    let val2 = getSlidesPerColumn();

    setSlidesPerView(val);
    setSlidesPerColumn(val2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiper, size]);

  // const calcSliderPerWidth = () => {
  //   const parentWidth = document.querySelector(".ProvidersSlider")?.offsetWidth;
  //   const sliderPerWidth = parentWidth / slidesPerView + 20;
  //   const sliderPerHeight = size.height / slidesPerColumn;
  //   if (sliderPerWidth < sliderPerHeight) {
  //     return sliderPerWidth;
  //   }
  //   return sliderPerHeight;
  // };

  // useEffect(() => {
  //   if (swiper) {
  //     swiper.update();
  //   }
  // }, [slidesPerView]);

  const renderProviders = () => {
    const exceptions = "ALL";
    return providerList?.map((provider, index) => {
      if (exceptions.includes(provider)) {
        return null;
      }

      return (
        <SwiperSlide key={`provider-${index}`}>
          <div
            className="item"
            data-provider={`${provider.toLowerCase()}`}
            key={`provider-${index}`}
            onClick={onClickProvider}
          >
            <img
              src={`./resources/sitepics/lobby/providers_redroyal/${provider
                .toLowerCase()
                .replace(/\s/gi, "_")
                .replace("aristoсrat", "aristocrat")
                .replace("novomatiс", "novomatic")
                .replace("sport", "live")}.jpg`}
              alt={provider}
            />
            <span
              className="label"
              style={{
                display: "none",
              }}
            >
              {provider.replace("_", " ").replace("betting", "games")}
            </span>
          </div>
        </SwiperSlide>
      );
    });
  };

  let paramsProviders = {
    slidesPerView: slidesPerView,
    className: "providers-swiper",
    navigation: {
      prevEl: ".btn-prev.providers-swiper-prev",
      nextEl: ".btn-next.providers-swiper-next",
    },
    slidesPerColumn: slidesPerColumn,
    spaceBetween: 15,
    slidesPerColumnFill: "row",
    onInit: (swiper) => setSwiper(swiper),
    initialSlide: window.localStorage.getItem("lastProviderSlide")
      ? window.localStorage.getItem("lastProviderSlide")
      : 0,
    onSlideChange: (swiper) => window.localStorage.setItem("lastProviderSlide", swiper.realIndex),
  };

  return (
    <div className="ProvidersSlider" data-visible={visible}>
      <div className="btn-prev providers-swiper-prev"></div>
      <Swiper {...paramsProviders}>{renderProviders()}</Swiper>
      <div className="btn-next providers-swiper-next"></div>
    </div>
  );
};
