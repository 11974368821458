import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Input } from '@bets/components'
import { PhoneInput, Button } from '@bets/elements'
import './ForgotForm.scss'

export const ForgotForm = ({ visible = true }) => {

  const words = useSelector(state => state.words)
  const settings = useSelector(state => state.settings)
  const [formData, setFormData] = useState({})
  const [success, setSuccess] = useState(false)
  const [type, setType] = useState(false)
  const [error, setError] = useState(false)

  window.core.functions.onQueryError = (data, response) => {
    data.cmd === "passwordRecovery" && setError(response.error)   
  }

  const restore = event => {
    event.preventDefault()

    const options = {
      cmd: "passwordRecovery",
      domain: window.location.hostname,
    }

    if (type === 'phone') {
      options.phone = formData.dialCode + formData.phone
    } else if (type === 'e_mail') {
      options.e_mail = formData.e_mail ? formData.e_mail : ''
    } 
  
    let response = window.core.query(options)

    if (response.status === "success") {
      setSuccess(true)
      setError(false)
    } else {
      setError(response.error)
      setSuccess(false)
    }
  }
  
  const getFormVal = (type, value) => {
    setError(false)
    setFormData({ ...formData, [type]: value })
  }

  const codeChange = (isvalid, value) => {
    getFormVal('dialCode', value.dialCode)
  }

  const numberChange = (isvalid, value, event) => {
    setError(false)
    setFormData({ ...formData, dialCode: event.dialCode, phone: value })
    let type = value && value.length > 0 && value !== '' ? 'phone' : false
    setType(type)
  }

  const emailChange = event => {
    let value = event.target.value
    getFormVal('e_mail', value)
    let type = value && value.length > 0 && value !== '' ? 'e_mail' : false
    setType(type)
  }

  const getValidate = () => {
    if (!settings.validate) return false
    let valid = !error && ((formData.e_mail && formData.e_mail !== '') || (formData.phone && formData.phone !== '')) ? false : true
    return valid
  }

  let or = settings.recoveryPassword.phone && settings.recoveryPassword.email

  return (
      <form className="Forgot form" data-visible={ visible } onSubmit={ event => restore(event) }
      >
          { settings.info && <p className='info'>{ words.server.enter_your_email }</p> }
          
          { settings.recoveryPassword.phone && 
            <PhoneInput 
              onChange={ numberChange } 
              onChangeCountry={ codeChange } 
              disabled={ type === 'e_mail' }/> 
          }
          { or && <div className="or"><span>{ words.server.or }</span></div> }
          { settings.recoveryPassword.email && 
            <Input 
              type="mail" 
              disabled={ type === 'phone' } 
              placeholder={ words.server.email } 
              onChange={ emailChange } />
          }

          <Button disabled={ getValidate() } className='submit-button'>{ words.server.confirm }</Button>
          
          { success && 
            <p className='success'>
              { type === 'email' ? words.server.recovery_success_email : words.server.recovery_success_phone }
            </p>
          }
          { (!success && error) && <div className="error">{ error }</div>}
      </form>
  )
}