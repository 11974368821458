import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import './A2hs.scss'

import Close from './assets/close.svg'

export const A2hs = () => {

	const words = useSelector(state => state.words)
	const preloader = useSelector(state => state.handling.preloader)

	const [visible, setVisible] = useState(true)

	useEffect(() => {
		var _this = window.core,
			deferredPrompt,
			addBtn = document.querySelector('.a2hs-btn'),
			closeBtn = document.querySelector('.a2hs-btn-close'),
			addBtnWrap = document.querySelector('.a2hs-btn-wrap'),
			chrome = document.querySelector('.chrome'),
			safari = document.querySelector('.safari'),
			isInstalled = localStorage.pwaInstalled ? localStorage.pwaInstalled : '0';

		if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
			localStorage.pwaInstalled = '1';
			isInstalled = true;

			$('body').addClass('ios-bottom-menu ios-fullscreen');
		} else {
			window.addEventListener('onappinstalled', () => {
				localStorage.pwaInstalled = '1';
				isInstalled = true;
			});
		}

		if ((device.iphone() || device.tablet()) && _this.detectBrowser() == 'Safari' && isInstalled != '1'){
			addBtnWrap.classList.add('active');
			safari.classList.add('active');
			addBtn.classList.add('hidden');
		}

		window.addEventListener('beforeinstallprompt', (e) => {
			e.preventDefault();
			deferredPrompt = e;

			if ((device.mobile() || device.tablet()) && _this.detectBrowser() == 'Chrome' && !localStorage.pwa) {
				addBtnWrap.classList.add('active');
				chrome.classList.add('active');
				addBtn.addEventListener('click', (e) => {
					e.preventDefault();
					addBtnWrap.classList.remove('active');

					deferredPrompt.prompt();
					deferredPrompt.userChoice.then((choiceResult) => {
						if (choiceResult.outcome === 'accepted') {
							//console.log('User accepted the A2HS prompt');
							localStorage.pwa = 'accepted';
						} else {
							//console.log('User dismissed the A2HS prompt');
							//localStorage.pwa = 'dismissed';
						}
						deferredPrompt = null;
					});
				});
			}
		});

		closeBtn && closeBtn.addEventListener('click', (e) => {
			addBtnWrap.classList.remove('active');
			localStorage.pwa = 'dismissed';
		});
    }, [])

	return (
		<>
		<div className="a2hs-btn-wrap" data-visible={ visible && !preloader } >
			<div className="a2hs-btn-window">
			
				<div className="chrome">
					<button className="a2hs-btn-icon">+</button>
					<button type="button" className="a2hs-btn">
						<span dangerouslySetInnerHTML={{ __html: words.server.a2hs_text_chrome_mobile }}></span>
					</button>
					
				</div>
				<div className="safari">
					<p  dangerouslySetInnerHTML={{ __html: words.server.a2hs_safari_mobile }} ></p>
				</div>
				<button className="a2hs-btn-close" onClick={ () => setVisible(false) }>
					<img src={ Close } alt="Close" />
				</button>
			</div>
		</div>
		</>
	)
}