// Smartphone
export const isSmall = { query: '(max-width: 480px) and (orientation: portrait)' };

// Tablet and retina device
export const isMedium = {
    query: '(min-width: 481px) and (max-width: 1024px), (-webkit-min-device-pixel-ratio: 1.5) and (max-width: 1024px), (orientation: landscape) and (max-width: 1024px)'
};

// Laptop, Desktop
export const isLarge = { query: '(min-width: 1025px) and (max-width: 1280px)' };

// extra Desktop
export const isExtraLarge = { query: '(min-width: 1281px)' };

// portaint 
export const isPortrait = { query: '(orientation: portrait)' };