import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Button } from '@bets/elements'
import { Convert } from '@bets/components/Payments/Convert'
import { PaymentSystems } from '@bets/components/Payments/PaymentSystems'
import { AmountInput } from '@bets/components/Payments/AmountInput'
import { WalletInput } from '@bets/components/Payments/WalletInput'
import { Success } from '@bets/components/Payments/Success'
import classNames from 'classnames'

import './Withdraw.scss'
import { setSelectedWithdraw, setWithdraw } from '@bets/components/redux/actions'

export const Withdraw = ({ visible, onClose, theme }) => {

	//const intl = useIntl()
	const dispatch = useDispatch()
	const profileInfo = useSelector(state => state.profile.info)
	const words = useSelector(state => state.words)
	const systems = useSelector(state => state.payments.withdraw.list)
	const selectedSystem = useSelector(state => state.payments.withdraw.select)

	const [error, setError] = useState('')
	const [correctSettings, setCorrectSettings] = useState(false)
	const [loading, setLoading] = useState(false)
	const [limits, setLimits] = useState(false)
	const [wallet, setWallet] = useState('')
	const [convertedCash, setConvertedCash] = useState(null)
	const [success, setSuccess] = useState(false)
	const [fields, setFields] = useState(false)

	const [options, setOptions] = useState({
		'payments': 'pay',
		'operation': 'out',
		'cash': 0,
		'system': false,
	})

	useEffect(() => {
		//console.log(profileInfo)

		let listResponse = window.core.query({ 'payments': 'list', 'currency': profileInfo.currency })

		let paymentResponse = window.core.query({
			'cmd': 'casinoGetPayment',
			'operation': 'out',
			'currency': profileInfo.currency
		})

		//window.core.getPayment('out', (e) => console.log(e))
		//console.log(salam)

		//console.log(listResponse, paymentResponse )

		if (paymentResponse.status === 'success') {
			let array = []
			//console.log(listResponse)
			//console.log(paymentResponse.content)
			for (let system in paymentResponse.content) {
				let item = paymentResponse.content[system].system

				if (listResponse.out_providers[item]) {
					//array.push(list.out_providers[item])
					array.push({ [item]: listResponse.out_providers[item] })
				}
			}
			//console.log(array)
			if (listResponse.limits[profileInfo.currency]) {
				setOptions(prevState => ({ ...prevState, cash: parseInt(listResponse.limits[profileInfo.currency].out.min, 10) }));
				setLimits(listResponse.limits[profileInfo.currency].out)
				//console.log( test.content )
				dispatch(setWithdraw(array))
			} else {
				console.log('Uncorrect payment settings')
			}
			
			//console.log(filteredSystems(list.out_providers))
			//console.log(array)
		}


		//setSystems( array )

		return (() => {
			dispatch(setSelectedWithdraw(false))
		}
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	useEffect(() => {
		if ((options.system !== 'oxprocessing' || options.system === 'anymoney') && options.system !== false) {
			let response = window.core.query({ 'payments': 'list', 'currency': profileInfo.currency })
			setLimits(response.limits[profileInfo.currency].out)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options.system])



	const handleConvert = (result) => {
		let limits = result.limits
		let item = Object.keys(limits)[0]

		if (!result.limits.error) {
			setLimits({ min: limits[item].out_min, max: limits[item].out_max })
			//console.log(result)
		}
		if (result && result.rate && result.rate.convert_amount) {
			setConvertedCash(result.rate.convert_amount)
		}
	}


	const handleSubmit = () => {

		setLoading(true)

		let response = window.core.query({
			"payments": "pay",
			"cash": options.cash,
			"system": 
				options.system + 
				'_' + 
				options.provider + 
				":" + 
				JSON.stringify({ account: wallet, converted_cash: convertedCash ? convertedCash : options.cash }),
			"operation":"out",
		})

		//const ops = options


		//ops.system = options.system + '_' + ops.provider + ":" + JSON.stringify({ account: wallet, converted_cash: convertedCash ? convertedCash : ops.cash })
		//console.log(ops)
		//let response = paymentResponse

		//console.log(response)

		if (response.status === 'success') {
			//console.log(response)
			setTimeout(() => {
				setLoading(false)
				setSuccess({
					text: '',
					operationId: response.content.operationId,
				})
			}, 500)
		}

		if (response.status === 'error') {
			setError(response.error.replace('lang->', ''))
			setLoading(false)
		}
	}

	const getValidate = (options) => {
		if (options.cash !== 0 && options.cash !== '' && options.cash !== '0' && options.system && options.provider && wallet !== '') {
			return true
		} else {
			return false
		}
	}

	const handleWalletField = event => {
		setWallet(event.target.value)
		setError('')
		//console.log(event.target.value)
	}

	const handleChange = event => {
		setError(false)
		//let value = parseInt(event.target.value, 10)
		setOptions(prevState => ({ ...prevState, cash: event.target.value }))
	}

	const handleSelect = (system, provider, key, currency, fields) => {
		setError(false)
		dispatch(setSelectedWithdraw(key))
		setFields(fields)
		setOptions(prevState => ({ ...prevState, system: system, provider: provider }))
	}

	const handleCloseSuccess = () => {
		setSuccess(false)
		setWallet('')
		dispatch(setSelectedWithdraw(false))
		setOptions(prevState => ({ ...prevState, cash: limits.min, system: false }))
	}

	const renderFields = fields => {
		return fields.map((field, index) => {
			return (
				<div key={'field_' + index} className="field" data-error={error === 'Enter Wallet'}>
					<span className='label'>
						{field.title}
					</span>
					<WalletInput name={field.name} value={wallet} onChange={handleWalletField} />
				</div>
			)
		})
	}

	return (
		<div className="Withdraw" data-visible={visible}>

			{!success && <div className="wrapper">

				{systems.length > 0 && <div className='form'>

					<span className='label'>{words.server.payment_system}</span>
					<PaymentSystems selected={selectedSystem} systems={systems} onSelect={handleSelect} />

					<div className='chargeable-balance'>
						<span className='text'>{words.server.chargeable_balance + ': '}</span>
						<span className="value">
							{profileInfo.wager.chargeable ? profileInfo.wager.chargeable : profileInfo.cash} {profileInfo.currency}
						</span>
					</div>



					{fields && renderFields(fields)}

					<div className="field" data-error={false}>
						<span className='label'>{words.server.amount}</span>
						<AmountInput
							value={options.cash}
							min={limits.min}
							max={limits.max}
							onChange={handleChange}
						/>
					</div>

					{((options.system === 'oxprocessing' || options.system === 'anymoney') && options.cash !== 0) &&
						<Convert
							amount={options.cash}
							operation={'out'}
							in_currency={options.provider}
							onConvert={result => handleConvert(result)}
						/>
					}

					{error && <div className='error'>{error}</div>}

					<div className="controls">
						<Button className={classNames("submit-button", {
							'button-royal': theme === 'royal'
						})} loading={loading} disabled={!getValidate(options)} onClick={handleSubmit}>
							<img className='loader' src='images/spinner.svg' alt="Loader" />
							<span>{words.server.withdraw}</span>
						</Button>
					</div>

				</div>}

			</div>}

			{success && <Success onClose={handleCloseSuccess} operationId={success.operationId} />}

		</div>
	)

	return <div className='incorrectSettings'>Uncorrect payments settings</div>

}