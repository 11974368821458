import React, { useState } from 'react'
import { Input } from '../Input'
import classNames from 'classnames';

import './PromoForm.scss'

export const PromoForm = (props) => {

    const [value, setValue] = useState(null)
    const [error, setError] = useState(null)

    const handleSubmit = event => {
        event.preventDefault()
        let options = { 'cmd': props.attributes.cmd }
        options[props.attributes.value] = value
        window.core.query(options, true, response => {
            setValue(null)
            if (response.status !== 'success') {
                setError(response.error)
            } else {
                setError(null)
                this.reloadTerminal()
            }
        })
    }

    const handleChange = event => {
        setValue(event.target.value)
        setError(null)
    }

    let disabled = (value !== null && value !== '') ? false : true
    let format_value = value !== null ? value : ''

    return (
        <form className={'PromoForm'} onSubmit={handleSubmit}>
            <div className='flex'>
                <Input
                    type="text"
                    value={format_value}
                    placeholder={props.attributes.placeholder}
                    onChange={handleChange}
                    className={props.theme} />

                <button className='submit' disabled={disabled}>
                    {props.attributes.submit_text}
                </button>
            </div>

            {error && <span className="error">{error}</span>}
        </form>
    )
}