import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getPopup } from '@bets/components/redux/actions'

import coins_ico from './assets/balance.png'
import './AccountButton.scss'

export const AccountButton = () => {

	const dispatch = useDispatch()
	const words = useSelector(state => state.words)
	const profile = useSelector(state => state.profile.info)
	const casino = useSelector(state => state.config.casino)
	const authorized = useSelector(state => state.profile.authorized)

	const handleAccountButton = () => {
		if (authorized) {
			dispatch( getPopup({ active: true, name: 'account' }) )
		} else {
			dispatch( getPopup({ active: true, name: 'auth' }) )
		}
	}
  
	return (
		<div className="AccountButton" onClick={ handleAccountButton }>
			<div className='text'>
				{ authorized ?
					<span className='user'> { profile.login }</span>
					:
					<>
						<span className='login'>{ words.server.login }</span>
						{ casino && <span className='signin'>{ ' / ' + words.server.registration }</span>}
					</>
				}
			</div>

			{ authorized && 
				<span className="cash">
					<img className='coins-ico' src={ coins_ico } alt="Coins" />
					{ profile.cash }
				</span> 
			}
		</div>
	)
}