import React from 'react'
import { useDispatch } from 'react-redux'
import {JackpotList, JackpotSpin} from '@bets/components'
import { ButtonClose } from '@bets/elements'
import { getPopup } from '@bets/components/redux/actions'

import './popup-jackpots.scss'


const PopupJackpots = () => {

    const dispatch = useDispatch()

    return (
        <div className="popup popup_jackpots">
          <JackpotSpin theme='royal' skipCountdown />
          <ButtonClose action={ () => dispatch( getPopup({ active: false, name: '' }) )}/>
        </div>
    )
}

export default PopupJackpots;
