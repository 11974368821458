import React,{useEffect} from 'react'
import './popup-info.scss'
import { ButtonClose } from '@bets/elements'

const PopupInfo = () => {

    useEffect(() => {
        window.core.elements.bonuses.push(".info-from-core");
    },[])

    return (
        <div className="popup popup_info">
            <ButtonClose action={ () => window.core.closeGame() } />
            <div className="info-from-core"></div>
        </div>
    )
}

export default PopupInfo;
