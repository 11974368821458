import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import './Download.scss'

import Android from './assets/android.svg'
import Windows from './assets/windows.svg'
import Apple from './assets/apple.svg'

export const Download = ({ title = false }) => {

	const size = useSelector(state => state.handling.size)
	const config = useSelector(state => state.config)
	const settings = useSelector(state => state.settings)
	const preloader = useSelector(state => state.handling.preloader)
	const [system, setSystem] = useState(null)

	useEffect(() => {
		let userAgent = navigator.userAgent
		
		var iOS = !window.MSStream && /iPad|iPhone|iPod/.test(userAgent);
		let android = userAgent.toLowerCase().indexOf("android") > -1
		let windows = /(win32|win64|windows|wince)/i.test(userAgent)
		if (iOS) {
			setSystem('ios')
		} else if (android) {
			setSystem('android')
		} else if(windows){
			setSystem('windows')
		} else {
			//console.log(userAgent)
			setSystem(null)
		}
	}, [])

	const renderLink = system => {
		switch (system) {
			case 'ios':
				return <a className="link" href={ config.iosAppDownloadLink } target="_blank" rel="noreferrer">
					<img src={ Apple } className="platform-logo" alt='Apple' />
					<p className="text"><span>IOS APP</span></p>
				</a>
			case 'windows':
				return <a className="link" href={ "update/Windows.exe" } target="_blank" rel="noreferrer">
					<img src={ Windows } className="platform-logo" alt={ 'Windows' } />
					<p className="text"><span>{ 'Windows' }</span></p>
				</a>
			case 'android':
				return <a className="link" href={ "update/Android.apk" } target="_blank" rel="noreferrer">
					<img src={ Android } className="platform-logo" alt={'Android'} />
					<p className="text"><span>{ 'Android' }</span></p>
				</a>
			default:
				break;
		}
		
	}

	let visible = settings.download.platforms[system] !== false && !window.core.checkIfFlashEnabled()

	if (visible) {
		return ( 
			<div className='download' data-visible={ !preloader }>
				{ title && <div className='title'>{ title }</div> }
				{ renderLink(system) }
			</div>
		)
	}

	return <></>
}