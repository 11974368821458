import React from 'react'
import './Attention.scss'

export const Attention = () => (
	<div className="user-attention">
		<div className="close-button Button ButtonClose"></div>
		<div className="messages">
			<div className="main"></div>
			<div className="additional"></div>
		</div>
		<p className="buttons"></p>
   	</div>
)