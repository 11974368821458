import React from 'react'
import { useSelector } from 'react-redux'
import classNames from "classnames";
import PropTypes from 'prop-types';

import './JackpotList.scss'

export const JackpotList = ({ type, theme }) => {

  const jackpots = useSelector(state => state.profile.info.jackpots)

  const renderElements = jackpots => (
    jackpots.map(jackpot =>
      <div key={jackpot.name} className={jackpot.activate ? "jackpot active" : "jackpot"}>
        <div className={classNames(`jackpot__name jackpot__name_${jackpot.name}`, {
          'jackpot__name-royal': theme === 'royal'
        })} name={jackpot.name}></div>
        <div className="jackpot__val">{jackpot.jackpot}</div>
      </div>
    )
  )

  if ((jackpots && jackpots.length > 0) || (window.core.jackpots && window.core.jackpots.length > 0)) {
    return (
      <div className="jackpots-list" data-type={type}>
        {
          jackpots ? renderElements(jackpots) : (window.core.jackpots && renderElements(window.core.jackpots))
        }
      </div>
    )
  } else {
    return <></>
  }
}

JackpotList.propTypes = {
  theme: PropTypes.oneOf(['royal', 'default'])
}

JackpotList.defaultProps = {
  theme: 'default'
}
