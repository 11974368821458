import React from 'react'
import { useSelector } from 'react-redux'

import rotate from './assets/rotate.svg'

import './PleaseRotate.scss'

export const PleaseRotate = ({ text = 'Please rotate phone', icon = rotate }) => {

  const gameFrame = useSelector(state => state.game.code)

	return (
		<div className="PleaseRotate" data-visible={ !gameFrame }>
      <img className='icon' src={ icon } alt="Flip Phone" />
      <div className='text'>{ text }</div>
    </div>
	)
}