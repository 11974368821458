import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from '@bets/elements'
import { setDeposit, setSelectedDeposit, getPopup } from '@bets/components/redux/actions'
import { PaymentSystems } from '@bets/components/Payments/PaymentSystems'
import { Convert } from '@bets/components/Payments/Convert'
import { AmountInput } from '@bets/components/Payments/AmountInput'
import classNames from 'classnames'


import './Deposit.scss'

export const Deposit = ({ visible, theme }) => {

	const dispatch = useDispatch()
	const profileInfo = useSelector(state => state.profile.info)
	const words = useSelector(state => state.words)
	const config = useSelector(state => state.config)
	const systems = useSelector(state => state.payments.deposit.list)
	const selectedSystem = useSelector(state => state.payments.deposit.select)
	const manualDeposit = useSelector(state => state.config.manualDeposit)

	const [form, setForm] = useState(false)
	const [correctSettings, setCorrectSettings] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState('')
	const [limits, setLimits] = useState(false)

	const [options, setOptions] = useState({
		'payments': 'pay',
		'operation': 'in',
		'cash': 0,
		'system': false,
		'provider': false
	})

	useEffect(() => {

		let response = window.core.query({ 'payments': 'list', 'currency': profileInfo.currency })

		if (response) {
			if (response.limits[profileInfo.currency]) {
				setCorrectSettings(true)
				setOptions(prevState => ({ ...prevState, cash: parseInt(response.limits[profileInfo.currency].in.min, 10) }));
				setLimits(response.limits[profileInfo.currency].in)
				dispatch(setDeposit(response.providers))
			} else {
				console.log('Uncorrect payment settings')
			}
			
			//console.log(response.providers)
		}
		return (() => {
			dispatch(setSelectedDeposit(false))
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {

		if ((options.system !== 'oxprocessing' || options.system === 'anymoney') && options.system !== false) {
			let response = window.core.query({ 'payments': 'list', 'currency': profileInfo.currency })
			setLimits(response.limits[profileInfo.currency].in)

		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options.system])

	const renderProcessingForm = (form, action, method) => {

		let openType = config.openDepositInNewTab === true ? "_blank" : "_self"
		let element = <form name='processingForm'
			target={openType}
			className='processingForm'
			onLoad={setTimeout(function () { document.processingForm.submit(); setLoading(false) }, 200)}
			action={action}
			method={method}
		>
			{Object.entries(form).map(item => <input key={item[0]} type="hidden" name={item[0]} value={item[1]} />)}
		</form>
		return setForm(element)
	}

	const handleSubmit = () => {

		if (selectedSystem !== 'manual') {
			setLoading(true)
			let response = window.core.query(options)

			if (response.status === 'success') {
				setError(false)
				renderProcessingForm(response.form, response.action, response.method)
			}

			if (response.status === 'error') {
				setError(response.error.replace('lang->', ''))
				setLoading(false)
			}
		} else {
			window.open(manualDeposit.url)
		}
	}

	const handleConvert = (result) => {
		let limits = result.limits
		let item = Object.keys(limits)[0]

		if (!result.limits.error) {
			setLimits({ min: limits[item].in_min, max: limits[item].in_max })
		}
		//console.log(limits[item].in_min, limits[item].in_max)
	}

	const getValidate = (options) => {
		if (selectedSystem !== 'manual') {
			if (options.cash !== 0 && options.cash !== '' && options.cash !== '0' && options.system && options.provider) {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}

	const handleChange = event => {
		setError(false)
		setOptions(prevState => ({ ...prevState, cash: event.target.value }))
	}

	const handleSelect = (system, provider, key) => {
		setError(false)
		dispatch(setSelectedDeposit(key))
		setOptions(prevState => ({ ...prevState, system: system, provider: provider }))
	}

	if (correctSettings) return (
		<div className="Deposit" data-visible={visible}>
			<div className="wrapper">

				{(systems.length > 0 || manualDeposit !== false) && <div className='form'>

					<span className='label'>{words.server.payment_system}</span>
					<PaymentSystems selected={selectedSystem} systems={systems} manual={manualDeposit} onSelect={handleSelect}>
						{settings.pntz && <button className={ 'payment-system'} onClick={() => dispatch( getPopup({ active: true, name: 'pntz' }))}><p>PNTZ</p></button>}
					</PaymentSystems>

					{selectedSystem !== 'manual' && <div className="field">
						<span className='label'>
							{words.server.amount}
						</span>
						<AmountInput
							value={options.cash}
							min={limits.min}
							max={limits.max}
							onChange={handleChange}
						/>
					</div>}

					{selectedSystem === 'manual' &&
						<div className='field'>
							<div className='manual-info'>
								<p dangerouslySetInnerHTML={{ __html: words.local.manualDepositInfo }}></p>
							</div>
						</div>
					}

					{((options.system === 'oxprocessing' || options.system === 'anymoney') && options.cash !== '' && options.cash !== 0) &&
						<Convert
							amount={options.cash}
							operation={'in'}
							system={options.system}
							in_currency={options.provider}
							onConvert={result => handleConvert(result)}
						/>
					}

					{error && <div className='error'>{error}</div>}

					<div className="controls">
						<Button className={classNames("submit-button", {
							'button-royal': theme === 'royal'
						})} loading={loading} disabled={!getValidate(options)} onClick={handleSubmit}>
							<img className='loader' src='images/spinner.svg' alt="Loader" />
							<span>{words.server.deposit}</span>
						</Button>

					</div>

					{form && form}
				</div>}

			</div>
		</div>
	)

	return <></>
}