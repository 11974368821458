import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonFavorite } from '@bets/elements'
import { getPopup, setGameFullscreen } from '@bets/components/redux/actions'
import classNames from "classnames";
import CardBorder from './assets/card_border.png'
import './GameCard.scss'


export const GameCard = ({ id, name, label, img, demo, fs, lazy, hidden }) => {

	const dispatch = useDispatch()
	const size = useSelector(state => state.handling.size)
	const authorized = useSelector(state => state.profile.authorized)
	const [opened, setOpened] = useState(false)

	const handlePlay = (id, label) => {
		if (authorized) {
			id === '1537' && dispatch(setGameFullscreen(false))
			window.core.openGame(id, label, 1, 0)
		} else {
			dispatch(getPopup({ active: true, name: 'auth' }))
		}
	}

	const renderImage = (img, name, lazy) => {
		if (lazy) {
			return <>
				<img className='image swiper-lazy' data-src={img} alt={name} />
				<div className="swiper-lazy-preloader">
					<img src="images/spinner.svg" alt="Loading" />
				</div>
			</>
		} else {
			return <img className="image" src={img} alt={name} />
		}
	}
	return (
		<div id={id} className={classNames("GameCard", {
			'GameCard-hidden': hidden
		})} data-opened={opened} onClick={() => setOpened(true)} onMouseEnter={() => setOpened(true)}>
			<div className="inner">

				{renderImage(img, name, lazy)}
				{opened &&
					<div className="content">
						{ size.mobile && <ButtonFavorite id={id} />}
						<p className="name">{name}</p>
						<div className="button play" onClick={() => handlePlay(id, label)} />
						{demo && <div className="button demo" onClick={() => window.core.openGame(id, label, 1, 1)} />}
					</div>
				}

				{fs && <div className="freespin" />}
			</div>
		</div>
	)
}