import React from 'react'
import { useSelector } from 'react-redux'

import './FullScreen.scss'

export const FullScreen = () => {

	const preloader = useSelector(state => state.handling.preloader)

	return (
		<div className="safarihelper-bg" data-visible={ !preloader }>
			<div id="safarihelper" />
		</div>
	)
}