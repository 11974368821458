import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "./index.scss";

import { App, Router, store } from "@bets/components"
import { Enter } from "./components"

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App>
        <Enter />
      </App>
    </Router>
  </Provider>,
  document.getElementById("root")
);
