import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, InputPassword } from '@bets/elements'

import './RecoveryForm.scss'

export const RecoveryForm = ({ visible = true }) => {

  const words = useSelector(state => state.words)
  const config = useSelector(state => state.config)
  const [formData, setFormData] = useState({})
  const [recoverySuccess, setRecoverySuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  window.core.functions.onQueryError = (data, response) => {
    data.cmd === "casinoPasswordRecovery" && setError(response.error)   
  }

  const restore = (e) => {

    e.preventDefault()

    if (formData.password !== formData.confrim_password) {
      setError(words.server.passwords_not_match)
    } else {
      setError(false)
      setLoading(true)

      let response = window.core.query({
        'cmd': 'casinoPasswordRecovery',
        'code': window.core.get.code,
        'password': formData.password
      })

      setTimeout( () => {
        if (response.status === 'success') {
          setRecoverySuccess(true)
          setLoading(false)
        } else {
          setError( response.error )
          setLoading(false)
        }
      }, 300)
    }
  }
  
  const setFormVal = (type, val) => {
    setFormData({ ...formData, [type]: val })
  }

  const getValidate = () => {
    if (!settings.validate) return false
    let valid = (formData.password && formData.password !== '') && (formData.confrim_password && formData.confrim_password !== '') && (formData.password === formData.confrim_password) ? false : true
    return valid
  }

  return (
    <form className="Recovery form" data-visible={ visible } onSubmit={(e) => restore(e)}>

      { !recoverySuccess &&
        <>
          <InputPassword 
            onChange={ (event) => setFormVal('password', event.target.value) }
            placeholder={ words.server.password }
            />

          <InputPassword 
            onChange={ (event) => setFormVal('confrim_password', event.target.value) }
            placeholder={ words.server.new_password }
            />

          <Button 
              className='submit-button'
              loading={ loading } 
              disabled={ getValidate() } >
              { words.server.confirm }
          </Button>

          { error && <div className={ "error" }>{ error }</div> }
        </>
      }

      { recoverySuccess && 
        <div className='success'>
          <p>{ words.server.recovery_password_change_successfull }</p>
        </div>
      }

    </form>
  )
}