import React, { useState, useEffect } from 'react'
import './Bonuses.scss'
import { useSelector } from 'react-redux'
import { Bonus } from './Bonus'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Mousewheel, Lazy } from 'swiper'

import Wager from './assets/wager.png'
import WagerLandscape from './assets/wager-landscape.png'

import CashbackBonus from './assets/cashback_bonus.png'
import CashbackBonusLandscape from './assets/cashback_bonus-landscape.png'

import FortuneWheelBonus from './assets/fortune_wheel_bonus.png'
import FortuneWheelBonusLandscape from './assets/fortune_wheel_bonus-landscape.png'

import AccumulatedBonus from './assets/accumulated_bonus.png'
import AccumulatedBonusLandscape from './assets/accumulated_bonus-landscape.png'

import WheelOfFreespins from './assets/wheel_of_freespins.png'
import WheelOfFreespinsLandscape from './assets/wheel_of_freespins-landscape.png'

import PromocodeBonus from './assets/promocode_bonus.png'
import PromocodeBonusLandscape from './assets/promocode_bonus-landscape.png'

SwiperCore.use([Navigation, Mousewheel, Lazy])

export const Bonuses = () => {

	const words = useSelector(state => state.words)
	//const config = useSelector(state => state.config)
	const linkBonus = useSelector(state => state.handling.linkBonus)
	const windowSize = useSelector(state => state.handling.size)
	const [swiper, setSwiper] = useState(false)

	const bonuses = [
		{
			id: 'wager',
			title: words.local.wager, 
			text: words.local.the_first_deposit,
			images: [Wager, WagerLandscape],
		},
		{
			id: 'cashback_bonus',
			title: words.local.cashback_bonus, 
			text: words.local.cashback_bonus_description,
			images: [CashbackBonus, CashbackBonusLandscape]
		},
		{
			id: 'fortune_wheel_bonus',
			title: words.local.fortune_wheel_bonus, 
			text:  words.local.fortune_wheel_bonus_description,
			images: [FortuneWheelBonus, FortuneWheelBonusLandscape]
		},
		{
			id: 'accumulated_bonus',
			title: words.local.accumulated_bonus, 
			text:  words.local.accumulated_bonus_description,
			images: [AccumulatedBonus, AccumulatedBonusLandscape]
		},
		{
			id: 'wheel_of_freespins',
			title: words.local.wheel_of_freespins, 
			text:  words.local.wheel_of_freespins_description,
			images: [WheelOfFreespins, WheelOfFreespinsLandscape]
		},
		{
			id: 'promocode_bonus',
			title: words.local.promocode_bonus, 
			text:  words.local.promocode_bonus_description,
			images: [PromocodeBonus, PromocodeBonusLandscape]
		}
		//accumulated_bonus: [words.local.accumulated_bonus, words.local.accumulated_bonus_description],
		//happy_hour_bonus: [words.local.happy_hour_bonus, words.local.happy_hour_bonus_description],
		//happy_hour_bonus_second: [words.local.happy_hour_bonus_second, words.local.happy_hour_bonus_second_description],
		//wheel_of_freespins: [words.local.wheel_of_freespins, words.local.wheel_of_freespins_description],
		//promocode_bonus: [words.local.promocode_bonus, words.local.promocode_bonus_description],
	]

    const renderSlides = () => (
		bonuses.map( (bonus, index) => {
			return (
				<SwiperSlide data-index={ index } key={ 'bonus-slide-' + bonus.id } id={ bonus.id }>
					<Bonus title={ bonus.title } images={ bonus.images } text={ bonus.text } id= { bonus.id } />
				</SwiperSlide>
			)
	}) )

	useEffect(() => {
		if (swiper) {
			swiper.lazy.load()
		}
	}, [windowSize.landscape, swiper])

	useEffect(() => {
		if (linkBonus && swiper) {
			let element = 0
			swiper.slides.map( (item, index) => { 
				if (item.id === linkBonus) { 
					element = index
				}
				return false
			})
			swiper.slideTo( element )
		}
		
		}, [linkBonus, swiper])

		
		if (!windowSize.mobile) {
			return (
				<div className="bonuses bonuses-popup">
					<Swiper
						key="game-slider"
						slidesPerView={1}
						navigation
						preloadImages={false}
						lazy
						pagination={{ clickable: true }}
						centerInsufficientSlides
						updateOnWindowResize
						mousewheel
						onSwiper={ swiper => setSwiper(swiper)}
					>
						{ renderSlides() }
					</Swiper>
				</div>
			)
		} else {
			return (
				<div className="bonuses">
					<Swiper
						key="game-slider"
						slidesPerView={1}
						width={windowSize.width}
						height={windowSize.height - 55}
						navigation
						preloadImages={false}
						lazy
						pagination={{ clickable: true }}
						centerInsufficientSlides
						updateOnWindowResize
						mousewheel
						onSwiper={swiper => setSwiper(swiper)}
					>
					{ renderSlides() }
					</Swiper>
				</div>
			)
		}
}