import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import CountUp from 'react-countup';

import { Rectangle } from '../List/Rectangle/Rectangle';

import './CompleteAnimation.scss';
import './Complete.scss';

import audioSrc from '../assets/complete-sound.mp3';
const audio = new Audio(audioSrc);
audio.type = "audio/mp3";
audio.volume = 0.5;

export const Complete = ({ jackpot, hiding, completeHidingCompletePage, theme }) => {
  useEffect(() => {
    setTimeout(() => {
      audio.play();
    }, 2000);

    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [])

  return (
    <>
      <div
        className={classNames('jackpot-complete', `jackpot-complete_${jackpot.name}`, {
          'jackpot-complete-hiding': hiding,
          'jackpot-complete-royal': theme === 'royal'
        })}
        onTransitionEnd={completeHidingCompletePage}
      >
        <Rectangle className={classNames('jackpot-complete__name', {
          'jackpot-complete__name-royal': theme === 'royal'
        })} type='light_sm' theme={theme}>
          <div className='jackpot-complete__name_content'></div>
          <div className='jackpot-complete__name_content_back'>WIN</div>
        </Rectangle>

        <Rectangle className={classNames('jackpot-complete__val', {
          'jackpot-complete__val-royal': theme === 'royal'
        })} type='light_big' theme={theme}>
          <CountUp decimals={2} delay={1.8} duration={3} end={jackpot.jackpot} />
        </Rectangle>
      </div>

      <div className={classNames('jackpot-complete__bright-bg', {
        'jackpot-complete__bright-bg-hiding': hiding
      })}></div>
    </>
  )
}

Complete.propTypes = {
  jackpot: PropTypes.any.isRequired,
  hiding: PropTypes.bool,
  theme: PropTypes.oneOf(['royal', 'default'])
}

Complete.defaultProps = {
  hiding: false,
  theme: 'default'
}