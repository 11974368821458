import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
//import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactComponent as Refresh } from './assets/refresh.svg';
import { ReactComponent as Info } from './assets/info.svg';
import classNames from 'classnames';
import './TransactionHistory.scss'
//import loader from 'images/spinner.svg'
import { useOutsideClick } from '@bets/hooks'

function dateToString(datetime, delimiter) {
    if (delimiter === undefined) {
        delimiter = '-';
    }
    var month = datetime.getMonth() + 1;
    if (month < 10) {
        month = '0' + month;
    }
    var date = datetime.getDate();
    if (date < 10) {
        date = '0' + date;
    }
    return datetime.getFullYear() + delimiter + month + delimiter + date;
}

const timeToString = (hours, minutes) => {
    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    return hours + ':' + minutes

}

Date.prototype.removeDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() - days);
    return date;
}

export const TransactionHistory = ({ theme }) => {

    //const intl = useIntl()
    const words = useSelector(state => state.words)

    const ref = useRef()
    const profileInfo = useSelector(state => state.profile.info)
    const [operationType, setOperationType] = useState('in')
    const [loading, setLoading] = useState(true)
    const [infoVisible, setInfoVisible] = useState(false)
    const [start, setStart] = useState({ date: dateToString(new Date()), time: timeToString(new Date().getHours(), new Date().getMinutes()) })
    const [end, setEnd] = useState({ date: dateToString(new Date().removeDays(60)), time: timeToString(new Date().getHours(), new Date().getMinutes()) })
    const [transactionsList, setTransactionsList] = useState([])
    const [error, setError] = useState('')

    useOutsideClick(ref, () => setInfoVisible(false))

    const getTransactions = (start, end, operation) => {
        console.log(start, end)
        setLoading(true)
        setTimeout(function () {
            let response = window.core.query({
                'cmd': 'casinoCashHistory',
                'start': end.date + " " + end.time + ":00",
                'end': start.date + " " + start.time + ":59",
                'operation': operation
            })

            if (response.status == 'success') {
                if (response.content.length > 0) {
                    setTransactionsList(response.content)
                    setError('')
                    setLoading(false)
                    //console.log('salam')
                } else {
                    setTransactionsList([])
                    setError('Transactions not found')
                    setLoading(false)
                }
            } else if (response.status == 'fail') {
                setError(response.error)
            }
        }, 500);

    }

    useEffect(() => {
        getTransactions(start, end, operationType)
    }, [operationType, start, end])


    const renderList = items => {
        let list = items.map((item) => (
            <div key={item.id} className="transaction">
                <div className='info'>
                    <span className="id">
                        {
                            //intl.messages.balance.id + ': ' + 
                            'ID: ' + item.id
                        }
                    </span>
                    <span>{item.datetime}</span>
                </div>
                <div className="amount">
                    <span className="value">{item.cash} </span>
                    {profileInfo.currency}
                </div>
            </div>
        ))

        return (
            <div className="list">
                {list}
            </div>
        )
    }

    const refreshHistory = () => {
        //console.log(timeToString(new Date().getHours(), new Date().getMinutes() ) )
        setStart({ date: dateToString(new Date()), time: timeToString(new Date().getHours(), new Date().getMinutes()) })
        setEnd({ date: dateToString(new Date().removeDays(60)), time: timeToString(new Date().getHours(), new Date().getMinutes()) })
    }

    return (
        <div className="TransactionHistory">
            <div className="head">
                <div className="title">
                    { //intl.messages.balance.history 
                        words.server.transaction_history
                    }
                </div>

                <div className="controls">
                    <div className='refresh' onClick={() => refreshHistory()}>
                        <Refresh />
                    </div>
                </div>
            </div>

            <div className={classNames("operation", {
                "operation-royal": theme === "royal"
            })}>
                <div className="in" data-active={operationType === 'in'} onClick={() => setOperationType('in')}>
                    { //intl.messages.balance.deposits 
                        words.server.deposit
                    }
                </div>
                <div className="out" data-active={operationType === 'out'} onClick={() => setOperationType('out')}>
                    { //intl.messages.balance.withdrawals
                        words.server.withdraw
                    }
                </div>
            </div>

            <div className="wrapper" data-loading={loading}>
                {loading && <div className='loader'><img src='images/spinner.svg' alt="Loading" /></div>}
                {(transactionsList.length > 0 && !loading) && renderList(transactionsList)}
                {error != '' && <div className="error">{error}</div>}
            </div>
        </div>
    )
}