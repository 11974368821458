import {
  GET_AUTHORIZATION,
  SET_BEFORE_AUTHORIZE,
  GET_GAMELIST,
  GET_ACTIVE_GAMELIST,
  GET_JACKPOTS,
  GET_PROFILE_INFO,
  GET_ACTIVE_POPUP,
  GET_ACTIVE_AUTH,
  GET_CONFIG,
  GET_WORDS,
  SET_GAME_CODE,
  SET_GAME_INFO,
  SET_GAME_FULLSCREEN,
  SET_GAME_CLOSE,
  GET_WINDOW_SIZE,
  GET_FAVORITE,
  GET_HISTORY_GAMES,
  GET_AUDIO,
  SET_PRELOADER,
  GET_LINK_BONUS,
  GET_SEARCH,
  RESET_STORE,
  SET_AUDIO_EVENT,
  SET_ACTIVE_INPUT,
  SET_PROMO,
  SET_DEPOSIT_LIST,
  SET_SELECTED_DEPOSIT,
  SET_WITHDRAW_LIST,
  SET_SELECTED_WITHDRAW
} from "./actionTypes";


export const getGameList = gameList => {
  return { type: GET_GAMELIST, payload: gameList }
}


export const setActiveGameList = (activeGameList) => {
  return {
    type: GET_ACTIVE_GAMELIST,
    payload: activeGameList,
  };
}

export function getAuthorization(authorized) {
  return {
    type: GET_AUTHORIZATION,
    payload: authorized,
  };
}

export function setBeforeAuthorize(authorized) {
  return {
    type: SET_BEFORE_AUTHORIZE,
    payload: authorized,
  };
}

export function getJackpots(jackpots) {
  return {
    type: GET_JACKPOTS,
    payload: jackpots,
  };
}

export function getProfileInfo(info) {
  return {
    type: GET_PROFILE_INFO,
    payload: info,
  };
}

export function getPopup(popup) {
  return {
    type: GET_ACTIVE_POPUP,
    payload: popup,
  };
}

export function getAuthForm(form) {
  return {
    type: GET_ACTIVE_AUTH,
    payload: form,
  };
}

export function getConfig(config) {
  return {
    type: GET_CONFIG,
    payload: config,
  };
}

export function getWords(words) {
  return {
    type: GET_WORDS,
    payload: words,
  };
}

export function getGameCode(code) {
  return { 
    type: SET_GAME_CODE,
    payload: code,
  };
}

export function setGameFullscreen(value) {
  return {
    type: SET_GAME_FULLSCREEN,
    payload: value,
  };
}

export function setGameClose() {
  return {
    type: SET_GAME_CLOSE
  };
}



export function setGameInfo(info, games) {
  let infoFromGamesList = games.gameList.filter((game) => game.id === info.id)[0]

  //console.log(infoFromGamesList,info)
  
  let newInfo = {
    id: info.id,
    //code: info.code,
    provider: info.provider,
    realProvider: info.realProvider,
    demo: info.demo,
    title: info.game.content.name,
  }

  if (games) {
    let newInfoGames = {
      label: infoFromGamesList.label,
      category: infoFromGamesList.category,
      image: infoFromGamesList.img,
      fs: infoFromGamesList.fs,
      top: infoFromGamesList.top,
      new: infoFromGamesList.new,
      type: infoFromGamesList.type,
  }
  newInfo = {...newInfo, ...newInfoGames}
  }

  return { 
    type: SET_GAME_INFO,
    payload: newInfo,
  };
}

export function getWindowSize(size) {
  return {
    type: GET_WINDOW_SIZE,
    payload: size,
  };
}

export function getFavoritGames(favorite) {
  return {
    type: GET_FAVORITE,
    payload: favorite,
  };
}

export function getHistoryGames(history) {
  return {
    type: GET_HISTORY_GAMES,
    payload: history,
  };
}

export function getAudioPlayer(player) {
  window.localStorage.setItem('paused', player)
  return {
    type: GET_AUDIO,
    payload: player,
  };
}

export function setAudioEvent(name) {
  return {
    type: SET_AUDIO_EVENT,
    payload: name,
  };
}

export function getLinkBonus(id) {
  return {
    type: GET_LINK_BONUS,
    payload: id,
  };
}


export const setPreloader = value => {
  return { type: SET_PRELOADER, payload: value }
}

export const setPromo = value => {
  return { type: SET_PROMO, payload: value }
}

export const setActiveInput = ref => {
  return { type: SET_ACTIVE_INPUT, payload: ref }
}

export function setSearch(term) {

  return {
    type: GET_SEARCH,
    payload: term
  };
}

export const setDeposit = (list) => {
  
  let array = []
  //console.log(list)
  for (let system in list) {
    let keys = Object.keys(list[system])
		let item = Object.values(list[system])
		if (item.length > 0) {
      //console.log(item)
      for (let provider in item) {
        //console.log(item[provider])
       
        let obj = {
          system: system,
          currency: item[provider].currency,
          provider: keys[provider],
          name: item[provider].name
        }
        array.push(obj)
      }
		}
	}

  console.log(array)

  return {
    type: SET_DEPOSIT_LIST,
    payload: array
  };
}

export const setSelectedDeposit = (key) => {
  return {
    type: SET_SELECTED_DEPOSIT,
    payload: key
  }
}

export const setWithdraw = (list) => {

  //console.log(list)
  let array = []
  //console.log(list)

  for (let system in list) {
    let keys = Object.keys(list[system])
		let item = Object.values(list[system])
		if (item.length > 0) {
      for (let provider in item[0]) {
        let obj = {
          system: keys[0],
          provider: provider,
          name: item[0][provider].name,
          currency: provider,
          fields: item[0][provider].fields
        }
        array.push(obj)
      }
		}
	}
  console.log(array)

  //console.log('Withdraw:', array)
  return {
    type: SET_WITHDRAW_LIST,
    payload: array
  };
}

export const setSelectedWithdraw = (key) => {

  return {
    type: SET_SELECTED_WITHDRAW,
    payload: key
  }
}

export const resetStore = () => {
  return {
    type: RESET_STORE
  }
}
