import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import './popup-sms.scss'
import { getPopup } from '@bets/components/redux/actions'
import { Input } from '@bets/components'
import { ButtonClose } from '@bets/elements'

const PopupSms = () => {

    const dispatch = useDispatch()
    const [formData, getFormData] = React.useState({})

    useEffect(() => {

    },[])

    const getFormVal = (type, val) => {
        //console.log('type',type);
        //console.log('val',val);
          getFormData({
              ...formData,
              [type]: val    
          })
    }

    return (
        <div className="popup popup_sms">
            <ButtonClose action={ () => dispatch( getPopup({ active: false, name: '' }) )}/>
            <Input inputType={"mail"} valType={"email"} placeholder="E-mail" getFormVal={getFormVal} />
        </div>
    )
}

export default PopupSms;
