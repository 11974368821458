import { combineReducers } from "redux"
import { gameReducer } from "./gameReducer"
import { gamesReducer } from "./gamesReducer"
import { handlingReducer } from "./handlingReducer"
import { profileReducer } from "./profileReducer"
import { configReducer } from "./configReducer"
import { wordsReducer } from "./wordsReducer"
import { paymentsReducer } from "./paymentsReducer"
import { settingsReducer } from "./settingsReducer"

export const reducer = combineReducers({
  words: wordsReducer,
  games: gamesReducer,
  config: configReducer,
  settings: settingsReducer,
  handling: handlingReducer,
  profile: profileReducer,
  game: gameReducer,
  payments: paymentsReducer,
})
