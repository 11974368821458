import { 
  SET_DEPOSIT_LIST, 
  SET_WITHDRAW_LIST,
  SET_SELECTED_DEPOSIT,
  SET_SELECTED_WITHDRAW
} from "../actions/actionTypes"

const initialState = {
  deposit: {
    select: false,
    list: false,
  },
  withdraw: {
    select: false,
    list: false,
  }
}

export const paymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEPOSIT_LIST:
      return {
        ...state,
        deposit: {
          ...state.deposit,
          list: action.payload
        },
      }
    case SET_SELECTED_DEPOSIT:
      return {
        ...state,
        deposit: {
          ...state.deposit,
          select: action.payload
        },
      }

    case SET_WITHDRAW_LIST:
      return {
        ...state,
        withdraw: {
          ...state.withdraw,
          list: action.payload
        },
      }

    case SET_SELECTED_WITHDRAW:
      return {
        ...state,
        withdraw: {
          ...state.withdraw,
          select: action.payload
        },
      }
    
    default:
      return state;
  }
}
