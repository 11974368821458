import React, { useEffect, useRef, useState } from 'react'
import  classNames from 'classnames';
import { useSelector } from 'react-redux'

import './ButtonLanguages.scss'

export const ButtonLanguages = ({ direction }) => {

    const ref = useRef()
    const config = useSelector(state => state.config)
    const [opened, setOpened] = useState(false)

    const renderLangs = () => {
        if (config.languages && config.languages.length > 1) {
            //const activeLang = window.config.system.language

            return config.languages.map( lang => { 
                //let active = activeLang === lang ? 'active' : ''
                return (
                    <div key={lang} className={`flag`} onClick={ () => window.core.setLanguage(lang) }>
                        <img className="image" src={`/resources/sitepics/lobby/languages/${lang}.svg` } alt={ lang } />
                    </div>
                )
            })
        }
    }

    useEffect(() => {
        document.getElementById('root').addEventListener("click", outsideClick)
        return () => { document.getElementById('root').removeEventListener("click", outsideClick) }
    }, [])
    
    const outsideClick = e => {
        (ref.current && !ref.current.contains(e.target)) && setOpened(false)
    }

    return (
        <div ref={ref} className={classNames('Button', 'ButtonLanguages')} data-opened={ opened } data-direction={ direction }>
            <div className="trigger" onClick={ () => setOpened(!opened) }>
                <div className={`flag ${window.config.system.language}`}>
                    <img className="image" src={ `/resources/sitepics/lobby/languages/${window.config.system.language}.svg`} alt={ window.config.system.language } />
                </div>
            </div>

            <div className='list'>{ renderLangs() }</div>
        </div>
    )
}