import React from 'react'
import { useSelector } from 'react-redux'
import { Auth } from '@bets/components/Auth'

import PopupInfo from './popup-info'
import PopupSms from './popup-sms'
import PopupBonuses from './popup-bonuses'
import PopupJackpots from './popup-jackpots'

import './Popup.scss'

export const Popup = () => {

    const popup = useSelector(state => state.handling.activePopup)

    const renderContent = (type) => {
        //console.log('SWITCH', type);
        switch (type) {            
            case 'auth':
                return (
                    <div className="popup popup_fullscreen" data-visible={ popup.active }>
                        <Auth />
                    </div>
                )
            case 'info':
                return <PopupInfo/>;
            case 'game':
            return <></>;
            case 'sms':
                return <PopupSms/>;
            case 'bonuses':
                return <PopupBonuses/>;
            case 'jackpots':
                return <PopupJackpots/>;
            default:
              return <></>;
        }
    }

    return (
            renderContent(popup.name)
    )    
}
