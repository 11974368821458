import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as d3Shape from 'd3-shape';
import wheel_bg from './../assets/wheel-bg.png'
import wheel_bg_cicle from './../assets/wheel-bg-cicle.png'
import wheel_board from './../assets/wheel-board.png'
import wheel_btn from './../assets/wheel-btn.png'
import knob_image from './../assets/knob.png'

import stage from './../assets/stage.png'
import wheel_f from './../assets/wheel-f.png'
import wheel_fs from './../assets/wheel-fs.png'

import wheel_bg_cicle_flash from './../assets/wheel-bg-cicle-flash.png'
import wheel_bg_cicle_flash_2 from './../assets/wheel-bg-cicle-flash-2.png'
import wheel_bg_cicle_flash_3 from './../assets/wheel-bg-cicle-flash-3.png'

import wheel_good_luck from './../assets/wheel-good-luck.png'

import './Wheel.scss'

export const Wheel = ({ values, win, isFreespins, onClick }) => {

    const landscape = useSelector(state => state.handling.size.landscape)

    const [spinDeg, setSpinDeg] = useState(null)
    const [winnerIndex, setWinnerIndex] = useState(null)
    const [count, setCount] = useState(null)
    const [showButton, setShowButton] = useState(false)

    const duration = 10000;
    const numberOfSegments = values.length;
    const oneTurn = 360;
    const angleBySegment = oneTurn / numberOfSegments;
    const angleOffset = angleBySegment / 2;

    let height, WHEEL_SIZE

    if ( landscape ) {
        height = window.outerHeight * 0.9
        WHEEL_SIZE = height * 0.8
    } else {
        height = window.outerWidth * 0.9
        WHEEL_SIZE = height * 0.8
    }
    
    const makeWheel = () => {
        const data = Array.from({ length: numberOfSegments }).fill(1);
        const arcs = d3Shape.pie().sort(null)(data);
        const colors = [ '#E07026', '#E8C22E', '#ABC937', '#4F991D', '#22AFD3', '#5858D0', '#7B48C8', '#D843B9', '#E23B80', '#D82B2B']
        return arcs.map((arc, index) => {
            const instance = d3Shape
                .arc()
                .padAngle(0)
                .outerRadius(height / 2)
                .innerRadius(30);
            return {
                path: instance(arc),
                color: colors[index % colors.length],
                value: values[index],
                centroid: instance.centroid(arc),
            }
        })
    }

    const textRender = (x, y, number, i, rotate) => {
        let scale = landscape ? 1.3 : 1.4
        return (
            <text
                transform={`rotate(${rotate} ${x * scale} ${y * scale})`}
                x={x * scale }
                y={y * scale }
                fontWeight="bold"
                fill="#fff"
                textAnchor="middle"
                stroke="#a10000"
                strokeWidth={0.5}
                fontSize={ landscape ? '4vh' : '5vw'}>
                    {Array.from({ length: number.length }).map((_, j) => {
                        return (
                            <tspan x={x * scale} dy={( height / 100) * 5} key={`arc-${i}-slice-${j}`}>
                                {number.charAt(j)}
                            </tspan>
                        );
                    })}
            </text>
        )
    }

    const spinAnimate = (winner) => {
        let index = values.indexOf(Number(winner))
        
        setWinnerIndex(index)
        setCount(count + 1)

        //user.sound && playWheelRotation();
        const rotate = 360 - index * (oneTurn / numberOfSegments) + 360 * (duration / 1000)

        setTimeout( () => setShowButton(true), duration)
        setSpinDeg(rotate)
    }

    useEffect(() => {
        
        win && spinAnimate(win)
    
    }, [win])

    let styles = { 
        transform: `rotate(${spinDeg - (angleBySegment / 2)}deg)`,
        transition: `${duration}ms`
    }

    let stylesKnob = {
        animationName: spinDeg !== 0 ? 'knob': '',
        animationIterationCount: (spinDeg / angleBySegment) / 6 ,
        animationDuration: duration / (spinDeg / angleBySegment) * 6 + 'ms'
    }

    const wheelPaths = makeWheel();

	return (
        <div className="Wheel1">

            <img className='stage' src={ stage } alt="Stage" />
            <img className='label' src={ isFreespins ? wheel_fs : wheel_f } alt="" />
            
            <div className="wheel-static">
                <div className="pie">
                    <div className='wheel-visualize' style={ styles }>
                        <svg xmlns="http://www.w3.org/2000/svg" 
                            width={WHEEL_SIZE}
                            height={WHEEL_SIZE}
                            viewBox={`0 0 ${height} ${height}`}
                            style={{ transform: [{ rotate: `-${angleOffset}deg` }] }}
                        >
                            <g id='wheel-svg'>
                                {wheelPaths.map((arc, i) => {
                                    const [x, y] = arc.centroid;
                                    const number = arc.value.toString();
                                    let rotate = (i * oneTurn) / numberOfSegments + angleOffset
                                    return (
                                        <g key={`arc-${i}`}>
                                            <path d={arc.path} strokeWidth={2} fill={arc.color} />
                                            <g
                                            >
                                                { textRender(x, y, number, i, rotate) }
                                            </g>
                                        </g>
                                    );
                                })}
                            </g>
                        </svg>
                    </div>

                </div>

                <img className='decoration' src={ wheel_bg } alt="" />
                <img className='decoration' src={ wheel_bg_cicle } alt="" />

                <img className='decoration flash flash-1' src={ wheel_bg_cicle_flash } alt="Circle Flash" />
                <img className='decoration flash flash-2' src={ wheel_bg_cicle_flash_2 } alt="Circle Flash" />
                <img className='decoration flash flash-3' src={ wheel_bg_cicle_flash_3 } alt="Circle Flash" />
                <img className='decoration board' src={ wheel_board } alt="" />

                <div className='knob' style={ stylesKnob }>
                    <img src={ knob_image } alt="Knob" />
                </div>
                {
                    showButton ? 
                    <div className='win-value'>{ values[winnerIndex] }</div>
                    : <img className='decoration flash flash-4' src={ wheel_good_luck } alt="" />
                }
                <div className="spin-button" data-disabled={ win ? true : false } onClick={ onClick }>
                    <img src={ wheel_btn } alt=""/>
                </div>
                
            </div>

            
        </div>
	)
}