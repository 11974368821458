import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDebounce } from '@bets/hooks'
import './Convert.scss'


export const Convert = ({ amount, system = 'oxprocessing', in_currency = 'USDT', operation = 'in', onConvert = () => {}}) => {
    
	const words = useSelector(state => state.words)
	const profileInfo = useSelector(state => state.profile.info)

	const [converted, setConverted] = useState(false)
	const debouncedConvert = useDebounce(amount, 500)

	useEffect(() => {
        if (debouncedConvert) {
			//console.log(debouncedConvert)

			let options = {
				payments: 'convert',
				system: system,
				in_currency: in_currency,
				operation: operation,
				amount: amount.toString()
			}
	
			let response = window.core.query(options)
			//console.log(response)
			if (response.status === 'success') {
				setConverted(response.rate)
				//console.log(response)
				onConvert(response)
				
			}

			if (response.status === 'error' || response.status === 'fail') {
				//console.log(response)
			}
			
        }
    }, [debouncedConvert, in_currency])

	return (
		<div className='Convert'>
			<p>
				<span>{ words.server.amount + ': ' }</span>
				<span>{ amount + ' ' + profileInfo.currency }</span>
			</p>
			<p>
				<span>{ words.server.convert_amount + ': ' }</span>
				{ converted && <span>{ converted.convert_amount + ' ' + converted.convert_currency }</span>}
			</p>
		</div>
	)
}